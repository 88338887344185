import React from 'react';
import Navigation from '../../components/Canteen/Navigation';
import StatisticsPrevision from '../../components/Canteen/StatisticsPrevision';

const StatisticsPrevisionPage = () => {
    return (
        <div className="dashboard d-flex">
            <Navigation color='-white' />
            <StatisticsPrevision />
        </div>
    );
};

export default StatisticsPrevisionPage;

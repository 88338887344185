import React from 'react';
import LoginTemplate from '../../components/Login/LoginTemplate';
import ResetPassword from '../../components/Login/ResetPassword';

const ResetPasswordPage = () => {
    return (
        <div className="login reset-password">
            <LoginTemplate child={<ResetPassword/>}/>
        </div>
    );
};

export default ResetPasswordPage;

import React from 'react';

const LoginTemplate = ({ child }) => {
    return (
        <div className="login-template d-flex">
            <div className="d-flex col-12 justify-content-center align-items-center flex-column">
                <span>
                    <img className="full-logo" alt="" src="/images/logo-white.png"></img>
                </span>

                <span className="login-subtext">
                    Manger mieux, jeter moins
                </span>

                { child }
            </div>
        </div>
    );
};

export default LoginTemplate;

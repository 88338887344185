import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import useAuth from '../hooks/useAuth';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Message = () => {
    // Global
    const { flash, showFlash, setShowFlash } = useAuth();

    // Close Flash Message
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowFlash(false);
    };

    return (
        <>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={showFlash} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={flash?.type ? flash.type : 'error'} sx={{ width: '90%' }}>
                    { flash.message }
                </Alert>
            </Snackbar>
        </>
    );
};

export default Message;

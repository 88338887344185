import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import LoginPage from './pages/Login/Login';
//import RegisterPage from './pages/Login/Register';
import ForgotPasswordPage from './pages/Login/ForgotPassword';
import ResetPasswordPage from './pages/Login/ResetPassword';
import SettingsPage from './pages/Canteen/Settings';
import DashboardPage from './pages/Canteen/Dashboard';
import MenusPage from './pages/Canteen/Menus';
import UnsoldMealsPage from './pages/Canteen/UnsoldMeals';
import CommunicationsPage from './pages/Canteen/Communications';
import CommunityPage from './pages/Canteen/Community';
import NotFoundPage from './pages/Error/NotFound';
import ServerErrorPage from './pages/Error/ServerError';
import UnauthorizedPage from './pages/Error/Unauthorized';
import StatisticsGlobalPage from './pages/Canteen/StatisticsGlobal';
import StatisticsMealPage from './pages/Canteen/StatisticsMeal';
import StatisticsReviewPage from './pages/Canteen/StatisticsReview';
import Profile from './pages/Canteen/ProfilePage';
import LogoutPage from './pages/Login/Logout';
import ConfidentialPage from './pages/Canteen/Confidential';
import CGUPage from './pages/Canteen/CGU';
import UpdatePasswordPage from './pages/Canteen/UpdatePasswordPage';
import HelpPage from './pages/Canteen/Help';
import HelpWorkPage from './pages/Canteen/HelpWork';
import HelpJoinPage from './pages/Canteen/HelpJoin';
import HelpContactPage from './pages/Canteen/HelpContact';
import HelpFAQPage from './pages/Canteen/HelpFAQ';
import NotificationPage from './pages/Canteen/Notification';
import AttendanceListPage from './pages/Canteen/AttendanceList';
import StatisticsMealReviewPage from './pages/Canteen/StatisticsMealReview';
import StatisticsPrevisionPage from './pages/Canteen/StatisticsPrevision';
import AdminDashboardPage from './pages/Canteen/AdminDashboard';

const Routing = () => {
    return (
        <Routes>
            {/* Public Routes */}
            <Route path="/login" element={ <LoginPage/> }></Route>
            {/*<Route path="/register" element={ <RegisterPage/> }></Route>*/}
            <Route path="/forgot-password" element={ <ForgotPasswordPage/> }></Route>
            <Route path="/reset-password/:email/:token" element={ <ResetPasswordPage/> }></Route>
            <Route path="/unauthorized" element={ <UnauthorizedPage/>} />

            {/* Routes Canteen */}
            <Route element={<RequireAuth allowedRoles={['ROLE_CANTEEN']} />}>
                <Route path="/" element={ <DashboardPage/> }>
                    <Route path="dashboard/:date" element={ <DashboardPage/> }></Route>
                </Route>
                <Route path="/attendance-list" element={ <AttendanceListPage/> }>
                    <Route path=":date" element={ <AttendanceListPage/> }></Route>
                </Route>
                <Route path="/my-profile" element={ <Profile/> }></Route>
                <Route path="/my-canteen" element={ <SettingsPage/> }></Route>
                <Route path="/statistics/global" element={ <StatisticsGlobalPage/> }></Route>
                <Route path="/statistics/global/:date" element={ <StatisticsGlobalPage/> }></Route>
                <Route path="/statistics/meal" element={ <StatisticsMealPage/> }></Route>
                <Route path="/statistics/meal-review" element={ <StatisticsMealReviewPage/> }></Route>
                <Route path="/statistics/prevision" element={ <StatisticsPrevisionPage/> }></Route>
                <Route path="/statistics/meal/:date" element={ <StatisticsMealPage/> }></Route>
                <Route path="/statistics/review" element={ <StatisticsReviewPage/> }></Route>
                <Route path="/statistics/review/:date" element={ <StatisticsReviewPage/> }></Route>
                <Route path="/menus" element={ <MenusPage/> }></Route>
                <Route path="/unsold-meals/:date?" element={ <UnsoldMealsPage/> }></Route>
                <Route path="/communications" element={ <CommunicationsPage/> }></Route>
                <Route path="/community" element={ <CommunityPage/> }></Route>
                <Route path="/logout" element={ <LogoutPage/> }></Route>
                <Route path="/confidential" element={ <ConfidentialPage/> }></Route>
                <Route path="/my-password" element={ <UpdatePasswordPage/> }></Route>
                <Route path="/cgu" element={ <CGUPage/> }></Route>
                <Route path="/help" element={ <HelpPage/> }></Route>
                <Route path="/help-work" element={ <HelpWorkPage/> }></Route>
                <Route path="/help-join" element={ <HelpJoinPage/> }></Route>
                <Route path="/help-contact" element={ <HelpContactPage/> }></Route>
                <Route path="/help-faq" element={ <HelpFAQPage/> }></Route>
                <Route path="/notifications" element={ <NotificationPage/> }></Route>
                <Route path="/admin/dashboard" element={ <AdminDashboardPage/> }>
                    <Route path=":date" element={ <AdminDashboardPage/> }></Route>
                </Route>
            </Route>

            {/* Routes Error */}
            <Route path="/500" element={ <ServerErrorPage/> }></Route>
            <Route path='/*' element={ <NotFoundPage/> }/>
        </Routes>
    );
};

export default Routing;

import React from 'react';
import Routing from './Routing';
import Loader from './components/Loader';
import Message from './components/Message';
import Modal from './components/Modal';
import NotificationPush from './components/NotificationPush';

const App = () => {
    return (
        <>
            <Routing />
            <Message />
            <Loader />
            <Modal />
            <NotificationPush />
        </>
    );
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom/client';
import i18next from 'i18next';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { AuthProvider } from './contexts/AuthProvider';
import common_fr from './translations/fr/common.json';
import './styles/index.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';

// Translations
i18next.init({
    interpolation: { escapeValue: false },
    lng: localStorage.getItem('locale') ?? 'fr',
    resources: {
        fr: { common: common_fr },
    },
});

// Application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={ i18next }>
        <BrowserRouter>
            <AuthProvider>
                <App />
            </AuthProvider>
        </BrowserRouter>
    </I18nextProvider>
);

serviceWorkerRegistration.register();

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const StatisticsPrevision = () => {
    // Global
    const { setIsLoading } = useAuth();
    const axiosPrivate = useAxiosPrivate()
    const [display, setDisplay] = useState(false);
    const [prevision, setPrevision] = useState(null);

    // Get Statistics
    const fetch = async() => {
        // Loader
        setIsLoading(true);

        // Call API
        const response = await axiosPrivate.get('/canteens/statistics/prevision', {
            params: {
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
            },
            withCredentials: true
        });

        setPrevision(response.data.stats);

        // Display
        setDisplay(true);

        // Loader
        setIsLoading(false);
    }

    const displayMobileMenu = () => {
        document.querySelector('.mobile-menu').classList.add('d-flex')
    }

    const hideMobileMenu = () => {
        const classes = document.querySelector('.mobile-menu').classList

        if( classes.contains('d-flex') ){
            document.querySelector('.mobile-menu').classList.remove('d-flex')
        }
    }

    const today = new Date();

    const getFirstDayOfMonth = () => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        return dayjs(firstDayOfMonth);
    };

    const getLastDayOfMonth = () => {
        const today = new Date();
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        return dayjs(lastDayOfMonth);
    };

    const [startDate, setStartDate] = useState(getFirstDayOfMonth());
    const [endDate, setEndDate] = useState(getLastDayOfMonth());

    const handleStartDateChange = (date) => {
        if (date !== null && (!endDate || date <= endDate)) {
            setStartDate(date);
        }
    };

    const handleEndDateChange = (date) => {
        if (date !== null && (!startDate || date >= startDate)) {
            setEndDate(date);
        }
    };

    useEffect(() => {
        fetch();
    }, [startDate, endDate]);

    return (
        <>
            { display &&
                <div className="d-flex col-12 col-md-10 align-items-center flex-column vh-100 global-stat">
                    <div className='statistics-header-mobile'>
                        <div className='statistics-header-menu' role='presentation' onClick={() => displayMobileMenu()}><img src='/images/menu-icon-white.png' alt='Menu'></img></div>
                        <div className='statistics-header-name'>Cantine du Sud</div>
                        <div className='statistics-header-avatar'>
                            <Link to='my-profile'>
                                <img src='/images/avatar-icon.png' alt='Menu'></img>
                            </Link>
                        </div>
                    </div>

                    <div className='statistics-body' role='presentation' onClick={() => hideMobileMenu()}>
                        <div className='statistics-heading'>Prévisions</div>

                        <div className='statistics-overview flex-column mt-4'>
                            <div className='d-flex justify-content-center align-items-center flex-column'>
                                <div className='stats-charts-head-title'>Affluence par établissement</div>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                        <div>
                                            <DatePicker
                                                value={startDate}
                                                onChange={handleStartDateChange}
                                                minDate={dayjs(today).add(-60, 'days')}
                                                maxDate={endDate}
                                            />
                                        </div>
                                        <div className='px-2'>-</div>
                                        <div>
                                            <DatePicker
                                                value={endDate}
                                                onChange={handleEndDateChange}
                                                minDate={startDate}
                                                maxDate={dayjs(today).add(90, 'days')}
                                            />
                                        </div>
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className='m-auto mt-4 w-50'>
                                <div className='mb-2 ms-2 fs-3'>Total : {prevision.total}</div>
                                <div className="accordion">
                                    {prevision.canteen && Object.keys(prevision.canteen).map((key, index) => (
                                        <div key={key} className="accordion-item" id={`stats-prevision-${index}`}>
                                            <h2 className="accordion-header" id={`heading-${index}`}>
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" aria-expanded="false" data-bs-target={`#collapse-${index}`} aria-controls={`collapse-${index}`}>
                                                    <div className="w-100">
                                                        <span className='float-start'>{prevision.canteen[key].canteenName}</span>
                                                        <span className='float-end me-4'>{prevision.canteen[key].total}</span>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div id={`collapse-${index}`} className="accordion-collapse collapse" aria-labelledby={`heading-${index}`} data-bs-parent={`stats-prevision-${index}`}>
                                                <div className="accordion-body">
                                                    {Object.keys(prevision.canteen[key].dates).map((key2) => (
                                                        <div key={key2} className="w-100" style={{height: '25px'}}>
                                                            <span className='float-start'>{dayjs(key2).format('DD/MM/YYYY')}</span>
                                                            <span className='float-end me-4'>{prevision.canteen[key].dates[key2]}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default StatisticsPrevision;

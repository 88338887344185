import React, { useEffect, useState } from 'react';
import StatisticReviewDetails from './StatisticReviewDetails';
import { Link } from 'react-router-dom';
import ChangeCanteen from './ChangeCanteen';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import DishType from './DishType';
import GlobalPercentage from './GlobalPercentage';

const StatisticsMealReview = () => {
    const { setIsLoading } = useAuth();
    const axiosPrivate = useAxiosPrivate()
    const [free, setFree] = useState(null);
    const [premium, setPremium] = useState(null);
    const [display, setDisplay] = useState(false);

    // Get Statistics
    const fetch = async() => {
        // Loader
        setIsLoading(true);

        // Call API
        const response = await axiosPrivate.get('/canteens/statistics/review', {
            params: {
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
            },
            withCredentials: true
        });

        setFree(response.data.stats.free);
        setPremium(response.data.stats.premium);

        // Display
        setDisplay(true);

        // Loader
        setIsLoading(false);
    }

    const displayMobileMenu = () => {
        document.querySelector('.mobile-menu').classList.add('d-flex')
    }

    const hideMobileMenu = () => {
        const classes = document.querySelector('.mobile-menu').classList

        if( classes.contains('d-flex') ){
            document.querySelector('.mobile-menu').classList.remove('d-flex')
        }
    }


    const today = new Date();

    const getPreviousMonday = () => {
        const dayOfWeek = today.getDay();
        const daysToSubtract = (dayOfWeek === 0) ? 6 : (dayOfWeek - 1);
        const previousMonday = new Date(today);
        previousMonday.setDate(today.getDate() - daysToSubtract);

        return dayjs(previousMonday);
    };

    const [startDate, setStartDate] = useState(getPreviousMonday());
    const [endDate, setEndDate] = useState(dayjs(today));

    const handleStartDateChange = (date) => {
        if (!endDate || date <= endDate) {
            setStartDate(date);
        }
    };

    const handleEndDateChange = (date) => {
        if (!startDate || date >= startDate) {
            setEndDate(date);
        }
    };

    useEffect(() => {
        fetch();
    }, [startDate, endDate]);

    return (
        <>
            { display &&
                <div className="d-flex col-12 col-md-10 align-items-center flex-column vh-100 global-stat">
                    <div className='statistics-header-mobile'>
                        <div className='statistics-header-menu' role='presentation' onClick={() => displayMobileMenu()}><img src='/images/menu-icon-white.png' alt='Menu'></img></div>
                        <div className='statistics-header-name'>Cantine du Sud</div>
                        <div className='statistics-header-avatar'>
                            <Link to='my-profile'>
                                <img src='/images/avatar-icon.png' alt='Menu'></img>
                            </Link>
                        </div>
                    </div>

                    <div className='statistics-body' role='presentation' onClick={() => hideMobileMenu()}>
                        <div className='statistics-heading'>Statistiques</div>

                        <div className='statistics-sub-heading'>
                            <Link to='/statistics/meal'>
                                <div className='statistics-sub-heading-text'>Alimentation</div>
                            </Link>

                            <div className='statistics-sub-heading-text statistics-sub-heading-active border-0'>Satisfaction</div>
                            <div className="d-flex justify-content-center div-change-canteen">
                                <ChangeCanteen location="/statistics/meal-review"/>
                            </div>
                        </div>

                        <div className='statistics-overview flex-column'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                    <div>
                                        <DatePicker
                                            value={startDate}
                                            onChange={handleStartDateChange}
                                            minDate={dayjs(today).add(-90, 'days')}
                                            maxDate={endDate}
                                        />
                                    </div>
                                    <div className='px-2'>-</div>
                                    <div>
                                        <DatePicker
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            minDate={startDate}
                                            maxDate={dayjs(today)}
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <StatisticReviewDetails name='Satisfaction générale' value={free.global}></StatisticReviewDetails>
                                <StatisticReviewDetails name='Visuel du plat' value={free.visual}></StatisticReviewDetails>
                                <StatisticReviewDetails name='Quantité' value={free.quantity}></StatisticReviewDetails>
                                <StatisticReviewDetails name='Goût' value={free.taste}></StatisticReviewDetails>
                            </div>
                        </div>

                        <div className={premium === null ? 'statistics-content statistics-content-blur' : 'statistics-content'}>
                            <div className='d-flex'>
                                {/* <div className='d-flex' style={{marginTop: '40px'}}> */}
                                <div className='stats-charts-container charts-desktop' style={{minWidth:'100%'}}>
                                    <div className='stats-charts-head-title'>Caractéristique des sélections</div>
                                    <table className='table text-center'>
                                        <thead>
                                            <tr>
                                                <th scope="col">Type</th>
                                                <th scope="col">Nom</th>
                                                <th scope="col">Nombre d&apos;avis</th>
                                                <th scope="col">Note globale</th>
                                                <th scope="col">Visuel</th>
                                                <th scope="col">Quantité</th>
                                                <th scope="col">Goût</th>
                                                <th scope="col">Plat sélectionné mais non consommé</th>
                                                <th scope="col">Plat consommé mais non sélectionné</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {premium.dishes.map((dish, index) => (
                                                <tr key={index}>
                                                    <td className='align-middle'><DishType value={dish.type}></DishType></td>
                                                    <td className='align-middle' style={{fontSize: '14px'}}>{dish.dishName}</td>
                                                    <td className='align-middle'>{dish.count}</td>
                                                    <td className='align-middle'><b><GlobalPercentage value={dish.global}></GlobalPercentage></b></td>
                                                    <td className='align-middle'>{dish.visual}%</td>
                                                    <td className='align-middle'>{dish.quantity}%</td>
                                                    <td className='align-middle'>{dish.taste}%</td>
                                                    <td className='align-middle'>{dish.dishNotVoteSelected}%</td>
                                                    <td className='align-middle'>{dish.dishVoteNotSelected}%</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default StatisticsMealReview;

import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

const AdminDashboard = () => {
    // Global
    const { setIsLoading } = useAuth();
    const axiosPrivate = useAxiosPrivate()
    const [stats, setStats] = useState({});
    const [display, setDisplay] = useState(false);

    // Data
    const param = useParams();
    const today = new Date()
    const currentDate = param?.date ? param.date : today.toJSON().slice(0, 10)

    // Get Statistics
    const fetch = async() => {
        // Loader
        setIsLoading(true);

        // Call API
        try {
            const response = await axiosPrivate.get(`/canteens/admin/statistics/dashboard?date=${currentDate}`, { withCredentials: true });
            setStats(response.data.stats);
        } catch (error) {
            console.error(error);
        }

        // Display
        setDisplay(true);

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        fetch();
    }, [param.date]);

    const displayMobileMenu = () => {
        document.querySelector('.mobile-menu').classList.add('d-flex')
    }

    const hideMobileMenu = () => {
        const classes = document.querySelector('.mobile-menu').classList

        if( classes.contains('d-flex') ){
            document.querySelector('.mobile-menu').classList.remove('d-flex')
        }
    }

    const current = dayjs(currentDate);

    const handleDateChange = (date) => {
        window.location =  `/admin/dashboard/${date.format('YYYY-MM-DD')}`;
    };

    const dateBefore = () => {
        window.location = `/admin/dashboard/${current.add(-1, 'day').format('YYYY-MM-DD')}`;
    };

    const dateAfter= () => {
        window.location = `/admin/dashboard/${current.add(1, 'day').format('YYYY-MM-DD')}`;
    };

    return ( display &&
        <div className="dashboard-block">
            <div className='dashboard-header-mobile'>
                <div className='statistics-header-menu' role='presentation' onClick={() => displayMobileMenu()}><img src='/images/menu-icon-white.png' alt='Menu'></img></div>
            </div>

            <div role='presentation' className='dashboard-body' onClick={() => hideMobileMenu()}>
                <div className='statistics-heading'>Admin Dashboard</div>
                <div className='d-flex global-stats-container flex-column'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='dashboard-resume d-flex'>
                            <span className='label'>Inscription</span>
                            <span className='number'>{stats.registration}</span>
                        </div>

                        <div className='dashboard-resume d-flex'>
                            <span className='label'>Compte</span>
                            <span className='number'>{stats.account}</span>
                        </div>
                    </div>
                    <div className='d-flex flex-fill justify-content-center align-items-center mt-4'>
                        <i role='presentation' onClick={() => dateBefore()} className='me-2 fa-solid fa-chevron-left fs-3'></i>
                        <div className='calendar-datepicker'>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                <DatePicker
                                    onChange={handleDateChange}
                                    defaultValue={current}
                                    loading={false}
                                />
                            </LocalizationProvider>
                        </div>
                        <i role='presentation' onClick={() => dateAfter()} className='ms-2 fa-solid fa-chevron-right fs-3'></i>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='dashboard-resume d-flex'>
                            <span className='label'>Feedback</span>
                            <span className='number'>{stats.feedback}</span>
                        </div>

                        <div className='dashboard-resume d-flex'>
                            <span className='label'>Vote</span>
                            <span className='number'>{stats.vote}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;

import { useCookies } from 'react-cookie';
import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    // Global
    const { auth, setAuth, setIsLoading } = useAuth();
    const [cookie, setCookie, removeCookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_user`]);

    // Logout
    const logout = async () => {
        await axios.post('/users/token/invalidate', {
            refresh_token: auth?.refreshToken,
        }, { withCredentials: true });

        setAuth({});
        localStorage.clear();
        removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, null);
        setIsLoading(false);
    }

    // Refresh Token
    return async () => {
        try {
            const response = await axios.post('/users/token/refresh', {
                refresh_token: auth?.refreshToken,
            }, {
                withCredentials: true,
            });

            setAuth(prev => {
                return {...prev, token: response.data.token}
            });

            cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['token'] = response.data.token;
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]);

            return response.data.token;
        } catch (err) {
            logout();

            return null;
        }
    };
};

export default useRefreshToken;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const Navigation = ({color=''}) => {
    // Global
    const location = useLocation();
    const {auth} = useAuth();

    // Links
    const links = [
        { url: '/', label: 'Tableau de bord', active: ['/'] },
        { url: '/statistics/prevision', label: 'Prévisions', active: ['/statistics/prevision'] },
        { url: '/statistics/meal', label: 'Statistiques', active: ['/statistics/meal', '/statistics/meal-review'] },
        // { url: '/menus', label: 'Menus' },
        // { url: '/unsold-meals', label: 'Invendus' },
        // { url: '/communications', label: 'Communications' },
        // { url: '/community', label: 'Communauté Jabu' },
    ];

    return (
        <>
            <div className='mobile-menu'>
                <Link to='/'>
                    <div className='mobile-menu-logo'>
                        <img src={'/images/logo.png'} alt='Logo'></img>
                    </div>
                </Link>

                <Link to='/'>
                    <div className='mobile-menu-item'>
                        <span className='mobile-menu-icon'>
                            <img src='/images/board-icon.png' alt='Tableau de bord'></img>
                        </span>
                        <span>Tableau de bord</span>
                    </div>
                </Link>

                <Link to='/statistics/prevision'>
                    <div className='mobile-menu-item'>
                        <span className='mobile-menu-icon'>
                            <img src='/images/stats-icon.png' alt='Statistiques'></img>
                        </span>
                        <span>Prévisions</span>
                    </div>
                </Link>

                <Link to='/statistics/meal'>
                    <div className='mobile-menu-item'>
                        <span className='mobile-menu-icon'>
                            <img src='/images/stats-icon.png' alt='Statistiques'></img>
                        </span>
                        <span>Statistiques</span>
                    </div>
                </Link>
                
                <Link to='/my-profile'>
                    <div className='mobile-menu-item'>
                        <span className='mobile-menu-icon'>
                            <img src='/images/avatar.png' alt='Avatar'></img>
                        </span>
                        <span>Mon profil</span>
                    </div>
                </Link>
            </div>

            <div className="menu d-flex flex-column px-5 pb-5 pt-4" style={{minHeight: '100%', position: 'fixed', width: '240px'}}>

                <div className="d-flex">
                    <Link to='/'>
                        <img className="logo" src={`/images/logo${color}.png`} alt='Logo'></img>
                    </Link>
                </div>


                <div className="d-flex menu-items-block justify-content-center align-items-center">
                    <ul className="menu-items">
                        { links.map((link, index) => {
                            let menuActive = (link.active.includes(location.pathname) ? 'menu-item__active' : '');
                            if (link.url === '/' && location.pathname.startsWith('/dashboard')) {
                                menuActive = 'menu-item__active';
                            }
                            return (
                                <Link key={index} to={link.url}>
                                    <li className={'menu-item ' + menuActive}>{link.label}</li>
                                </Link>
                            );
                        })}
                        <Link to="/my-profile"><li className={'menu-item ' + (['/my-profile', '/my-canteen', '/my-password', '/help', '/cgu', '/confidential'].includes(location.pathname) ? 'menu-item__active' : '') }>Mon profil</li></Link>
                    </ul>
                </div>

                <div className="d-flex justify-content-center align-items-end">
                    <div className='avatar-name-block'>
                        <span className='avatar-name'>{auth?.firstName.slice(0, 1)}{auth?.lastName.slice(0, 1)}</span>
                        {/* <img className='avatar' src='/images/user.png' alt='avatar'></img> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navigation;

import React from 'react';

const GlobalPercentage = ({value}) => {
    let className = '';
    if( value < 50 ){
        className = 'text-danger';
    } else if( value >= 50 && value < 75 ){
        className = 'text-warning';
    } else if( value >= 75 ){
        className = 'text-success';
    }

    return (
        <span className={className}>{value}%</span>
    );
};

export default GlobalPercentage;

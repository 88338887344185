import React from 'react';
import ErrorTemplate from '../../components/Error/ErrorTemplate';

const ServerErrorPage = () => {
    return (
        <div className="server-error">
            <ErrorTemplate h1="Erreur Serveur" />
        </div>
    );
};

export default ServerErrorPage;

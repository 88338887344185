import React from 'react';
import Navigation from '../../components/Canteen/Navigation';
import StatisticsMealReview from '../../components/Canteen/StatisticsMealReview';

const StatisticsMealReviewPage = () => {
    return (
        <div className="statistics d-flex">
            <Navigation color='-white' />
            <StatisticsMealReview />
        </div>
    );
};

export default StatisticsMealReviewPage;

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Alert from '../Alert';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';

const ForgotPassword = () => {
    // Global
    const navigate = useNavigate();
    // Form
    const { register, handleSubmit, formState: { errors } } = useForm();
    // Provider
    const { setFlash, setShowFlash, setIsLoading } = useAuth();

    // Call Submit Forgot Password Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            const response = await axios.post('/users/password/token', {
                email: data.username,
            }, { withCredentials: true });

            setFlash({ message: 'Un Email a été envoyé', type: 'success' });
            // Todo To delete after Email
            console.log(response.data.token);
            navigate('/login');
        } catch (err) {
            if (err?.response?.status === 404) {
                setFlash({ message: 'Email n\'existe pas' });
            } else {
                navigate('/500');
            }
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    return (
        <div className='login-form'>
            <span className="fw-bold mt-3">Mot de passe oublié ?</span>

            <span className="login-new d-flex justify-content-center align-items-center text-center">
                Saisissez l&apos;adresse e-mail associée à votre compte afin de recevoir un lien pour réinitialiser votre mot de passe
            </span>

            <form className="d-flex flex-column" onSubmit={handleSubmit(submit)}>
                <Alert/>
                <input className="login-field" type="text" placeholder="Adresse email" {...register('username', {
                    required: true,
                    maxLength: 50,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                })} ></input>
                {errors?.username?.type === 'required' && <div className="text-danger small">{'Adresse email obligatoire'}</div>}
                {errors?.username?.type === 'maxLength' && <div className="text-danger small">{'Maximum 50 caractères'}</div>}
                {errors?.username?.type === 'pattern' && <div className="text-danger small">{'Mauvais format'}</div>}
                <button className="login-submit" type="submit">Réinitialiser mon mot de passe</button>
            </form>

            <div className="login-back text-center">
                <span><Link to="/login"><u><b>Retour</b></u></Link></span>
            </div>
        </div>
    );
};

export default ForgotPassword;

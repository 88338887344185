import React from 'react';
import Navigation from '../../components/Canteen/Navigation';
import Dashboard from '../../components/Canteen/Dashboard';

const DashboardPage = () => {
    return (
        <div className="dashboard d-flex">
            <Navigation color='-white' />
            <Dashboard />
        </div>
    );
};

export default DashboardPage;

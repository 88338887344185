import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import StatisticMealDetails from './StatisticMealDetails';
import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis} from 'recharts';
import ChangeCanteen from './ChangeCanteen';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const StatisticsMeal = () => {
    const { setIsLoading } = useAuth();
    const axiosPrivate = useAxiosPrivate()
    const [free, setFree] = useState(null);
    const [premium, setPremium] = useState(null);
    const [display, setDisplay] = useState(false);
    const canteenId = localStorage.getItem('canteen_id');

    //const colorsFavoriteMeal = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22'];

    // Get Statistics
    const fetch = async() => {
        // Loader
        setIsLoading(true);

        // Call API
        const response = await axiosPrivate.get(`/canteens/statistics/meal?canteenId=${canteenId}`, { withCredentials: true });

        let totalUsers = response.data.stats.total;

        // Update Free %
        let dataFree = response.data.stats.free;
        let updatedFree = Object.keys(dataFree).reduce((item, key) => {
            item[key] = { ...dataFree[key], count: Math.round((dataFree[key].count / totalUsers) * 100)};
            return item;
        }, {});

        // Update Premium %
        let dataPremium = response.data.stats.premium;
        let updatedPremium = {};
        for (const key of Object.keys(dataPremium)) {
            updatedPremium[key] = dataPremium[key].map(item => ({
                ...item,
                value: Math.round((item.value / totalUsers) * 100)
            }));
        }

        setFree(updatedFree);
        setPremium(updatedPremium);

        // Display
        setDisplay(true);

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        fetch();
    }, []);

    const displayMobileMenu = () => {
        document.querySelector('.mobile-menu').classList.add('d-flex')
    }

    const hideMobileMenu = () => {
        const classes = document.querySelector('.mobile-menu').classList

        if( classes.contains('d-flex') ){
            document.querySelector('.mobile-menu').classList.remove('d-flex')
        }
    }

    return (
        <>
            { display &&
                <div className="d-flex col-12 col-md-10 align-items-center flex-column vh-100 global-stat">
                    <div className='statistics-header-mobile'>
                        <div className='statistics-header-menu' role='presentation' onClick={() => displayMobileMenu()}><img src='/images/menu-icon-white.png' alt='Menu'></img></div>
                        <div className='statistics-header-name'>Cantine du Sud</div>
                        <div className='statistics-header-avatar'>
                            <Link to='my-profile'>
                                <img src='/images/avatar-icon.png' alt='Menu'></img>
                            </Link>
                        </div>
                    </div>

                    <div className='statistics-body' role='presentation' onClick={() => hideMobileMenu()}>
                        <div className='statistics-heading'>Statistiques</div>

                        <div className='statistics-sub-heading'>
                            {/*<Link to='/statistics/global'>
                                <div className='statistics-sub-heading-text'>Général</div>
                            </Link>*/}

                            <div className='statistics-sub-heading-text statistics-sub-heading-active'>Alimentation</div>

                            <Link to='/statistics/meal-review'>
                                <div className='statistics-sub-heading-text border-0'>Satisfaction</div>
                            </Link>
                            <div className="d-flex justify-content-center div-change-canteen">
                                <ChangeCanteen location="/statistics/meal"/>
                            </div>
                        </div>

                        <div className='statistics-overview flex-column'>
                            {/*<div className='d-flex justify-content-center'>
                                <div className='statistic-button meal'>
                                    {monthStat1 === currentMonth &&
                                        <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-left' onClick={() => setMonthStat1(currentMonth - 1)}></i>
                                    }
                                    <span className='flex-grow-1 text-center'>{months[monthStat1 - 1]}</span>
                                    {monthStat1 !== currentMonth &&
                                        <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-right' onClick={() => setMonthStat1(currentMonth)}></i>
                                    }
                                </div>
                            </div>*/}
                            <div className='d-flex justify-content-center'>
                                <StatisticMealDetails icon={free.top_dietary_constraint.icon} name='Top régime alimentaire' value={free.top_dietary_constraint.name} count={free.top_dietary_constraint.count}></StatisticMealDetails>
                                <StatisticMealDetails icon={free.top_allergen.icon} name='Top allergie' value={free.top_allergen.name} count={free.top_allergen.count}></StatisticMealDetails>
                                {/*<StatisticMealDetails icon={free.top_favorite_meal.icon} name='Top plats favoris' value={free.top_favorite_meal.name} count={free.top_favorite_meal.count}></StatisticMealDetails>*/}
                            </div>
                        </div>

                        <div className={premium === null ? 'd-flex flex-column statistics-content statistics-content-blur' : 'd-flex flex-column statistics-content'}>
                            <div className='d-flex w-100 my-4'>
                                <div className='stats-charts-container' style={{minWidth: '100%'}}>
                                    <div className='stats-charts-head-title'>Allergies</div>
                                    {/*<div className='d-flex justify-content-center'>
                                        <div className='statistic-button meal'>
                                            {weekStat2 === currentWeek &&
                                                <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-left' onClick={() => setWeekStat2(currentWeek - 1)}></i>
                                            }
                                            <span className='flex-grow-1 text-center'>S{weekStat2}</span>
                                            {weekStat2 !== currentWeek &&
                                                <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-right' onClick={() => setWeekStat2(currentWeek)}></i>
                                            }
                                        </div>
                                    </div>*/}
                                    <div className='w-100' style={{minHeight: '300px'}}>
                                        <ResponsiveContainer width="100%" minWidth={350} minHeight={300}>
                                            <BarChart margin={{top: 20}} data={premium !== null ? premium.top_allergen : []}>
                                                <Tooltip/>
                                                <XAxis dataKey="allergen" tick={{fontSize: 10}}/>
                                                <Bar dataKey="value" fill="#E984B733" label={{position: 'top', formatter: (value) => `${value}%`}}/>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex w-100 my-4'>
                                <div className='stats-charts-container' style={{minWidth: '100%'}}>
                                    <div className='stats-charts-head-title'>Régime alimentaire</div>
                                    {/*<div className='d-flex justify-content-center'>
                                        <div className='statistic-button meal'>
                                            {weekStat1 === currentWeek &&
                                                <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-left' onClick={() => setWeekStat1(currentWeek - 1)}></i>
                                            }
                                            <span className='flex-grow-1 text-center'>S{weekStat1}</span>
                                            {weekStat1 !== currentWeek &&
                                                <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-right' onClick={() => setWeekStat1(currentWeek)}></i>
                                            }
                                        </div>
                                    </div>*/}
                                    <div className='w-100'>
                                        <ResponsiveContainer width="100%" minWidth={300} minHeight={300}>
                                            <BarChart margin={{top: 20}} data={premium !== null ? premium.top_dietary_constraint : []}>
                                                <Tooltip/>
                                                <XAxis dataKey="constraint" tick={{fontSize: 10}}/>
                                                <Bar dataKey="value" fill="#6E3C9433" label={{position: 'top', formatter: (value) => `${value}%`}}/>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                            {/*<div className='d-flex w-100 my-4'>
                                <div className='stats-charts-container' style={{minWidth: '100%'}}>
                                    <div className='stats-charts-head-title'>Plats favoris</div>
                                    {<div className='d-flex justify-content-center'>
                                        <div className='statistic-button meal'>
                                            {monthStat2 === currentMonth &&
                                                <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-left' onClick={() => setMonthStat2(currentMonth - 1)}></i>
                                            }
                                            <span className='flex-grow-1 text-center'>{months[monthStat2 - 1]}</span>
                                            {monthStat2 !== currentMonth &&
                                                <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-right' onClick={() => setMonthStat2(currentMonth)}></i>
                                            }
                                        </div>
                                    </div>
                                    <div className='w-100'>
                                        <ResponsiveContainer width="100%" minWidth={300} minHeight={300}>
                                            <PieChart>
                                                <Pie
                                                    data={premium.top_favorite_meal}
                                                    innerRadius={60}
                                                    outerRadius={80}
                                                    fill="#8884d8"
                                                    paddingAngle={5}
                                                    dataKey="value"
                                                    label={({value}) => `${value}%`}
                                                >
                                                    {premium.top_favorite_meal.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={colorsFavoriteMeal[index % colorsFavoriteMeal.length]}/>
                                                    ))}
                                                </Pie>
                                                <Legend align="left" layout="vertical" verticalAlign="middle" payload={premium.top_favorite_meal.map((entry, index) => ({value: entry.meal, color: colorsFavoriteMeal[index % colorsFavoriteMeal.length]}))}/>
                                                <Tooltip formatter={(value, name, props) => `${props.payload.meal}: ${value}%`}/>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>*/}
                        </div>

                        {premium === null &&
                            <div className='statistics-offer-upgrade'>
                                <div className='statistics-offer-title'>Statistiques sur l’alimentation de vos
                                    convives
                                </div>
                                <div className='statistics-offer-subtitle'>Envie de connaitre ce dont vos convives ont
                                    envie et besoin ?
                                </div>
                                <div className='statistics-offer-content'>Découvrez exactement quels sont les régimes
                                    alimentaires, allergies et plats favoris de vos convives afin d’adapter vos
                                    préparations aux besoins et envies
                                </div>
                                <div className='statistics-offer-available'>Disponible en offre Premium</div>
                                <Link to='https://meetings-eu1.hubspot.com/axel-galliano/revue-offres-jabu'>
                                    <div
                                        className='statistics-offer-available-button statistics-offer-available-button-pink'>Passer
                                        à l’offre supérieure
                                    </div>
                                </Link>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default StatisticsMeal;

import React, { useEffect, useState } from 'react';
import MealCalendar from './MealCalendar';
import DashboardDetails from './DashboardDetails';
import {useNavigate, useParams} from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import StatisticReviewDetails from './StatisticReviewDetails';
//import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

const Dashboard = () => {
    // Global
    const { setIsLoading } = useAuth();
    const axiosPrivate = useAxiosPrivate()
    const [free, setFree] = useState(null);
    const [standard, setStandard] = useState(null);
    const [premium, setPremium] = useState(null);
    const [display, setDisplay] = useState(false);
    const canteenId = localStorage.getItem('canteen_id');
    const navigate = useNavigate();

    // Data
    const param = useParams();
    const today = new Date()
    const currentDate = param?.date ? param.date : today.toJSON().slice(0, 10)

    // Get Statistics
    const fetch = async() => {
        // Loader
        setIsLoading(true);

        // Call API
        const response = await axiosPrivate.get(`/canteens/statistics/dashboard?date=${currentDate}&canteenId=${canteenId}`, { withCredentials: true });

        setFree(response.data.stats.free);
        setStandard(response.data.stats.standard || null);
        setPremium(response.data.stats.premium || null);

        // Display
        setDisplay(true);

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        fetch();
    }, [param.date]);

    const displayMobileMenu = () => {
        document.querySelector('.mobile-menu').classList.add('d-flex')
    }

    const hideMobileMenu = () => {
        const classes = document.querySelector('.mobile-menu').classList

        if( classes.contains('d-flex') ){
            document.querySelector('.mobile-menu').classList.remove('d-flex')
        }
    }

    const satisfaction = () => {
        navigate('/statistics/meal-review');
    }

    return ( display &&
        <div className="dashboard-block">
            <div className='dashboard-header-mobile'>
                <div className='statistics-header-menu' role='presentation' onClick={() => displayMobileMenu()}><img src='/images/menu-icon-white.png' alt='Menu'></img></div>
                {/*<div className='statistics-header-name'>Cantine du Sud</div>
                <div className='statistics-header-avatar'>
                    <Link to='my-profile'>
                        <img src='/images/avatar-icon.png' alt='Menu'></img>
                    </Link>
                </div>*/}
            </div>

            <MealCalendar currentDate={currentDate}></MealCalendar>
            
            <div role='presentation' className='dashboard-body' onClick={() => hideMobileMenu()}>
                {/*<div className='d-flex align-items-center'>
                    <div className='dashboard-heading'>
                        <NavLink to={`/dashboard/${currentDate}`} className='active'>
                            Tableau de bord
                        </NavLink >
                    </div>
                    <div className='dashboard-heading'>
                        <NavLink to={`/attendance-list/${currentDate}`}>
                            Liste de présence
                        </NavLink >
                    </div>
                </div>*/}

                <div className='d-flex global-stats-container flex-column'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='dashboard-resume d-flex'>
                            <span className='label'>Votes</span>
                            <span className='number'>{free.review_count}</span>
                        </div>

                        <div className='dashboard-resume dashboard-estimated-affluence d-flex' style={{maxWidth: '150px'}}>
                            <span className='label label-green'>Estimation IA</span>
                            <span className='number label-green'>{standard?.attendance}</span>
                        </div>

                        <div className='dashboard-resume dashboard-resume-full d-flex flex-grow-1'>
                            <div className='label label-full'>
                                <span>Top 3 sélections</span>
                                <span className='mobile-hidden label-icon'><i className="fa-solid fa-star"></i></span>
                            </div>
                            <div style={{textAlign: 'start', fontSize: '1vw'}}>
                                {free.top_meal.map((meal, index) => (
                                    <div className='d-flex align-items-center' key={index}>
                                        <span className='dashboard-selection-number'>{index + 1}.</span>
                                        <span className='dashboard-selection-label'>{meal.name}</span>
                                        <span className='dashboard-selection-count ms-1 d-flex'>
                                            <span>-</span>
                                            <span>{meal.count}</span>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div role='presentation' onClick={() => satisfaction()} className='dashboard-resume dashboard-estimated-affluence d-flex' style={{maxWidth: '250px'}}>
                            <StatisticReviewDetails name='Satisfaction Générale' value={standard?.satisfaction}></StatisticReviewDetails>
                        </div>

                        {/*<div className='dashboard-resume dashboard-affluence'>
                            <div className='label label-full'>
                                <span>Prévision d&apos;affluence</span>
                            </div>
                            <div style={{ textAlign: 'start' }}>
                                <ResponsiveContainer minWidth={250} minHeight={350}>
                                    <BarChart margin={{ top: 20 }} data={standard !== null ? standard.affluence : []}>
                                        <Tooltip/>
                                        <XAxis tick={{fontSize: 12}} dataKey="name"/>
                                        <Bar dataKey="count" stackId="affluence" fill="#F3C121" label={{ fill: 'white', position: 'insideTop' }} name='Votant(s)' />
                                        <Bar dataKey="estimated" stackId="affluence" fill="#EE4447" label={{ fill: 'white', position: 'insideTop' }} name='Convives estimées restantes' />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>*/}
                    </div>
                    <div className='dashboard-table'>
                        <div className="text-center fs-4">
                            Detail des menus sélectionnés
                        </div>
                        <div>
                            <DashboardDetails free={free} standard={standard} premium={premium}></DashboardDetails>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

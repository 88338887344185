import React from 'react';
import useAuth from '../hooks/useAuth';

const Loader = () => {
    // Provider
    const { isLoading } = useAuth();

    return (
        <>
            { isLoading &&
                <div className="loader-container">
                    <span className="loader"></span>
                </div>
            }
        </>
    );
};

export default Loader;

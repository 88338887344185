import { useEffect } from 'react';
import useLogout from '../../hooks/useLogout';

const LogoutPage = () => {
    // Global
    const logout = useLogout();

    useEffect(() => {
        logout();
    });
};

export default LogoutPage;

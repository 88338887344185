import React from 'react';
import Navigation from '../../components/Canteen/Navigation';
import StatisticsReview from '../../components/Canteen/StatisticsReview';

const StatisticsReviewPage = () => {
    return (
        <div className="statistics d-flex">
            <Navigation color='-white' />
            <StatisticsReview />
        </div>
    );
};

export default StatisticsReviewPage;

import React from 'react';
import Navigation from '../../components/Canteen/Navigation';
import Community from '../../components/Canteen/Community';

const CommunityPage = () => {
    return (
        <div className="community d-flex">
            <Navigation/>
            <Community/>
        </div>
    );
};

export default CommunityPage;

import React from 'react';
import Navigation from '../../components/Canteen/Navigation';
import StatisticsMeal from '../../components/Canteen/StatisticsMeal';

const StatisticsMealPage = () => {
    return (
        <div className="statistics d-flex">
            <Navigation color='-white' />
            <StatisticsMeal />
        </div>
    );
};

export default StatisticsMealPage;

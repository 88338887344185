import React from 'react';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const ChangeCanteen = ({location}) => {
    const canteens = JSON.parse(localStorage.getItem('canteens'));
    const canteenId = localStorage.getItem('canteen_id');
    const { setIsLoading } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const handleChange = async (event) => {
        // Loader
        setIsLoading(true)

        const canteenId = event.target.value

        try {
            await axiosPrivate.put('/canteens/canteen', {
                canteen_default_id: canteenId
            }, { withCredentials: true });
            localStorage.setItem('canteen_id', canteenId);
            window.location = location
        } catch (error) {
            console.log(error);
        }

        // Loader
        setIsLoading(false)
    }

    return (
        <>
            { canteens !== null && canteens.length > 1 &&
                <select className="select-canteen" defaultValue={canteenId} onChange={handleChange}>
                    {
                        canteens.map((canteen) => (
                            <option value={canteen.id} key={canteen.id}>{canteen.name}</option>
                        ))
                    }
                </select>
            }
        </>
    );
};

export default ChangeCanteen;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../components/Canteen/Navigation';
import ProfileMenu from '../../components/Canteen/ProfileMenu';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import ChangeCanteen from '../../components/Canteen/ChangeCanteen';

const SettingsPage = () => {
    // Global
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [display, setDisplay] = useState(false);
    // Form
    const { reset, register, handleSubmit, formState: { errors } } = useForm();
    // Provider
    const { setFlash, setShowFlash, setIsLoading } = useAuth();

    // Call Submit Canteen Setting Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            await axiosPrivate.put('/canteens/setting', {
                type: parseInt(data.canteenType),
                effective: parseInt(data.effective),
                days: data.days.map((day) => parseInt(day)),
                lunch: {
                    start: data.startLunch + ':00',
                    end: data.endLunch + ':00'
                },
                unsold: {
                    start: data.startUnsold + ':00',
                    end: data.endUnsold + ':00'
                },
            }, { withCredentials: true });

            setFlash({ message: 'Modification effectuée', type: 'success' });
        } catch (err) {
            let error;
            if (err?.response?.status === 404) {
                error = 'Réglages Cantine non existant.';
            } else {
                error = 'Erreur Serveur';
                navigate('/500');
            }

            setFlash({ message: error });
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    // Get Canteen Setting
    const fetch = async() => {
        // Loader
        setIsLoading(true);

        // Call API
        const response = await axiosPrivate.get('/canteens/setting', { withCredentials: true });
        let data = response.data;
        reset({
            days: data?.days.map((day) => String(day)) ?? [],
            startLunch: data?.lunch?.start ? data?.lunch?.start.slice(0,5) : '12:00',
            endLunch: data?.lunch?.end ? data?.lunch?.end.slice(0,5) : '14:00',
            //startUnsold: data?.unsold?.start ? data?.unsold?.start.slice(0,5) : '14:00',
            //endUnsold: data?.unsold?.end ? data?.unsold?.end.slice(0,5) : '16:00',
            canteenType: String(data?.type) ?? '2',
            effective: String(data?.effective) ?? '0',
        });

        // Display Form
        setDisplay(true);

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        fetch();
    }, []);

    return (
        <div className="profile settings d-flex">
            <Navigation />
            <div className='profile-detail min-vh-100'>
                <div className='profile-title'>
                    <span role='presentation' className='menu-hamburger'><img src='/images/menu-icon.png' alt='Menu'></img></span>
                    <span className='profile-title-text'>Mes informations cantine</span>
                    <span className='profile-avatar'><img style={{height: '24px'}} src='/images/avatar.png' alt='Avatar'></img></span>
                </div>
                <div className="d-flex my-3">
                    <div className="position-absolute end-0 me-4">
                        <ChangeCanteen location="/my-canteen"/>
                    </div>
                </div>

                <div className='d-flex col-md-2 px-5 py-2 w-100 flex-grow-1'>
                    <ProfileMenu active='setting'></ProfileMenu>
                    <div className="d-flex flex-column align-items-center p-2 m-4 flex-grow-1">
                        <span className="sub-title">Jours d&apos;ouverture de votre cantine</span>
                        { display &&
                            <form className="d-flex align-items-center flex-column" onSubmit={handleSubmit(submit)}>
                                <span className="days-open d-flex justify-content-center align-items-center text-center">
                                    <label><input type="checkbox" name="days" value="1" {...register('days', {required: true})} /><span className="day-open">Lundi</span></label>
                                    <label><input type="checkbox" name="days" value="2" {...register('days')} /><span className="day-open">Mardi</span></label>
                                    <label><input type="checkbox" name="days" value="3" {...register('days')} /><span className="day-open">Mercredi</span></label>
                                    <label><input type="checkbox" name="days" value="4" {...register('days')} /><span className="day-open">Jeudi</span></label>
                                    <label><input type="checkbox" name="days" value="5" {...register('days')} /><span className="day-open">Vendredi</span></label>
                                    <label><input type="checkbox" name="days" value="6" {...register('days')} /><span className="day-open">Samedi</span></label>
                                    <label><input type="checkbox" name="days" value="0" {...register('days')} /><span className="day-open">Dimanche</span></label>
                                </span>
                                {errors?.days?.type === 'required' &&
                                    <div className="text-danger small">{'Jours obligatoire'}</div>}

                                <div className="d-none days-opening d-flex flex-column justify-content-center align-items-center text-center">
                                    <span className="sub-title">Horaires d&apos;ouverture</span>
                                    <span>De <input className="canteen-slot" type="time" defaultValue="12:00" {...register('startLunch', {required: true})} ></input> à <input className="canteen-slot" type="time" defaultValue="14:00" {...register('endLunch', {required: true})} ></input></span>
                                    {errors?.startLunch?.type === 'required' &&
                                        <div className="text-danger small">{'Ouverture obligatoire'}</div>
                                    }
                                    {errors?.endLunch?.type === 'required' &&
                                        <div className="text-danger small">{'Fermeture obligatoire'}</div>
                                    }
                                </div>

                                <div className="d-none days-opening d-flex flex-column justify-content-center align-items-center text-center border-top-0 mt-0">
                                    <span className="sub-title">Horaires d&apos;ouverture Invendus</span>
                                    <span>De <input className="canteen-slot" type="time" defaultValue="14:00" {...register('startUnsold', {required: true})} ></input> à <input className="canteen-slot" type="time" defaultValue="16:00" {...register('endUnsold', {required: true})} ></input></span>
                                    {errors?.startUnsold?.type === 'required' &&
                                        <div className="text-danger small">{'Ouverture obligatoire'}</div>
                                    }
                                    {errors?.endUnsold?.type === 'required' &&
                                        <div className="text-danger small">{'Fermeture obligatoire'}</div>
                                    }
                                </div>

                                <span className="d-none sub-title">Tarifs de la cantine</span>
                                <div className="d-none">
                                    <span className="choose-canteen">Tarif unique <input className="btn-choose-canteen-price" type="radio" name="canteen-price" value="1" {...register('canteenType', {required: true})} ></input></span>
                                    <span className="choose-canteen">Tarif par article <input className="btn-choose-canteen-price" type="radio" name="canteen-price" value="2" defaultChecked {...register('canteenType')}></input></span>
                                    {errors?.canteenType?.type === 'required' &&
                                        <div className="text-danger small">{'Tarif obligatoire'}</div>
                                    }
                                </div>

                                <div className="days-opening d-flex flex-column justify-content-center align-items-center text-center border-top-0 mt-0">
                                    <span className="sub-title">Effectifs</span>
                                    <input className="canteen-slot" {...register('effective', {required: true})} ></input>
                                    {errors?.effective?.type === 'required' &&
                                        <div className="text-danger small">{'Effectif obligatoire'}</div>
                                    }
                                </div>

                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <input className='user-submit' type='submit' value='Valider'></input>
                                    <input className='user-cancel' type='button' value='Annuler'></input>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsPage;

import React, { useEffect, useState } from 'react';
import MealCalendar from './MealCalendar';
import { Link, NavLink, useParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import ChangeCanteen from './ChangeCanteen';
import useAuth from '../../hooks/useAuth';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

const AttendanceList = () => {
    // Global
    const { setIsLoading } = useAuth();
    const axiosPrivate = useAxiosPrivate()
    const [reservations, setReservations] = useState(null);
    const [display, setDisplay] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    // Data
    const param = useParams();
    const today = new Date()
    const currentDate = param?.date ? param.date : today.toJSON().slice(0, 10)

    const loadCanteens = async () => {
        // Loader
        setIsLoading(true)

        const canteens = await axiosPrivate.get('/canteens', { withCredentials: true });
        let storageCanteen = [];
        canteens.data.canteens.map((canteen) => {
            storageCanteen.push({
                'id': canteen.id,
                'name': canteen.name,
            })
        })
        localStorage.setItem('canteens', JSON.stringify(storageCanteen));
        localStorage.setItem('canteen_id', canteens.data.canteenId);

        // Loader
        setIsLoading(false)
    }

    // Get Attendance List
    const fetch = async() => {
        // Loader
        setIsLoading(true);

        // Call API
        const response = await axiosPrivate.get(`/canteens/reservations?date=${currentDate}&canteenId=${localStorage.getItem('canteen_id')}`, { withCredentials: true });
        setReservations(response.data.reservations);
        setFilteredData(response.data.reservations);

        // Display
        setDisplay(true);

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        if (localStorage.getItem('canteens') === null) {
            loadCanteens().then(() => { fetch() })
        }else{
            fetch()
        }
    }, [param.date]);

    const displayMobileMenu = () => {
        document.querySelector('.mobile-menu').classList.add('d-flex')
    }

    const hideMobileMenu = () => {
        const classes = document.querySelector('.mobile-menu').classList

        if( classes.contains('d-flex') ){
            document.querySelector('.mobile-menu').classList.remove('d-flex')
        }
    }

    const filterDataByName = (name) => {
        if (name.trim() === '') {
            setFilteredData(reservations);
        } else {
            const filtered = reservations.filter(item => item.lastName.toLowerCase().includes(name.toLowerCase()));
            setFilteredData(filtered);
        }
    };

    return ( display &&
        <div className="dashboard-block">
            <div className='dashboard-header-mobile'>
                <div className='statistics-header-menu' role='presentation' onClick={() => displayMobileMenu()}><img src='/images/menu-icon-white.png' alt='Menu'></img></div>
                <div className='statistics-header-name'>Cantine du Sud</div>
                <div className='statistics-header-avatar'>
                    <Link to='my-profile'>
                        <img src='/images/avatar-icon.png' alt='Menu'></img>
                    </Link>
                </div>
            </div>

            <MealCalendar currentDate={currentDate}></MealCalendar>
            
            <div role='presentation' className='dashboard-body' onClick={() => hideMobileMenu()}>
                <div className='d-flex align-items-center'>
                    <div className='dashboard-heading'>
                        <NavLink to={`/dashboard/${currentDate}`}>
                            Tableau de bord
                        </NavLink >
                    </div>
                    <div className='dashboard-heading'>
                        <NavLink to={`/attendance-list/${currentDate}`} className='active'>
                            Liste de présence
                        </NavLink >
                    </div>
                    <div className="dashboard-heading mobile-hidden">
                        <ChangeCanteen location="/"/>
                    </div>
                </div>

                <div className='d-flex'>
                    <div className='dashboard-table attendance-table'>
                        <input className="search" type="text" placeholder="Rechercher" onChange={(e) => filterDataByName(e.target.value)}/>
                        <div className="text-center fs-4">
                            Detail des menus sélectionnés
                        </div>
                        <div>
                            <TableContainer component={Paper} sx={{
                                boxShadow: {xs: 'none'},
                                backgroundColor: 'white',
                                padding: {md: '20px 40px'}
                            }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Nom</TableCell>
                                            <TableCell>Prénom</TableCell>
                                            <TableCell>Statut de Réservation</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredData.map((reservation) => {
                                            return <TableRow key={reservation.id}>
                                                <TableCell>{reservation.id}</TableCell>
                                                <TableCell>{reservation.lastName}</TableCell>
                                                <TableCell>{reservation.firstName}</TableCell>
                                                <TableCell>
                                                    {reservation.reserved === true ?
                                                        reservation.here === true ?
                                                            <div className="d-flex"><span
                                                                className="d-inline-block point reserved"></span><span>Réservé</span>
                                                            </div>
                                                            : <div className="d-flex"><span
                                                                className="d-inline-block point not-here"></span><span>Non Scanné</span>
                                                            </div>
                                                        : <div className="d-flex"><span
                                                            className="d-inline-block point not-reserved"></span><span>Non Réservé</span>
                                                        </div>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {(reservation.reserved === false || reservation.reserved === null) ?
                                                        reservation.here === true ?
                                                            <button className="btn btn-danger">Annuler le
                                                                passage</button>
                                                            : <button className="btn btn-success">Valider le
                                                                passage</button>
                                                        : ''
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AttendanceList;

import React from 'react';
import ChangeCanteen from './ChangeCanteen';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import 'dayjs/locale/fr';
//import { Link } from 'react-router-dom';

const MealCalendar = ({ currentDate, url = 'dashboard', location = '/' }) => {
    /*const days = ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.']
    const months = ['jan.', 'fév.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.']
    let currentDay, pastDay
    let calendarDates = []

    currentDate = new Date(currentDate)
    currentDay = new Date()
    pastDay = new Date().setDate(currentDay.getDate() - 1)

    let formattedCurrentDate, formattedCurrentDateMonth = undefined
    formattedCurrentDate = `${days[currentDate.getDay()]} ${currentDate.getDate()}`
    formattedCurrentDateMonth = months[currentDate.getMonth()]

    const closingDays = []

    let dates = [pastDay, currentDay.getTime()]
    for (let i=1; i<=11; i++) {
        dates.push(new Date().setDate(currentDay.getDate() + i))
    }
    dates.sort()
    
    dates.forEach((date) => {
        calendarDates.push(new Date(date))
    })

    let dayClass, daySizeClass = undefined
    const today = new Date()
    const calendar = dates.map((date, key) => {
        date = new Date(date)

        if( date.toJSON().slice(0, 10) !== currentDate.toJSON().slice(0, 10) && false == closingDays.includes(date.getDay()) ){
            dayClass = (date > today) ? 'day-after' : 'day-before';
            daySizeClass = ( key <=4 ) ? 'day-s' : ( key <= 6 ) ? 'day-m' : ( key <= 8 )  ? 'day-l' : ( key <= 10 )  ? 'day-xl' : 'day-xxl';

            return <div key={key} className={`${daySizeClass} d-flex align-items-center border-bottom border-dark p-1 flex-column`}>
                <Link key={date.toJSON().slice(0, 10)} to={`/dashboard/${date.toJSON().slice(0, 10)}`}>
                    <div className={`calendar-day ${dayClass}`}>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='calenday-day-name'>{days[date.getDay()]}</div>
                            <div className='calenday-day-number'>{date.getDate()}</div>
                        </div>

                        <div className='d-flex'>
                            <div className='calenday-day-month'>{ months[date.getMonth()] }</div>
                        </div>
                    </div>
                </Link>
            </div>
        }

        if( date.toJSON().slice(0, 10) !== currentDate.toJSON().slice(0, 10) && closingDays.includes(date.getDay()) ){
            daySizeClass = (4 <= key) ? 'day-s' : ( key <= 6 ) ? 'day-m' : ( key <= 8 )  ? 'day-l' : ( key <= 10 )  ? 'day-xl' : 'day-xxl';
            
            return <div key={key} className={`${daySizeClass} d-flex align-items-center border-bottom border-dark p-1 flex-column`}>
                <div className='calendar-day day-closed'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='calenday-day-name'>{days[date.getDay()]}</div>
                        <div className='calenday-day-number'>{date.getDate()}</div>
                    </div>

                    <div className='d-flex'>
                        <div className='calenday-day-month'>{ months[date.getMonth()] }</div>
                    </div>
                </div>
            </div>
        }
        
        if( date.toJSON().slice(0, 10) == currentDate.toJSON().slice(0, 10) ){
            return <div key={key} className='day-s current-day flex-grow-1 current-day d-flex align-items-center border border-dark border-bottom-0 rounded-top flex-column'>
                <div className='fw-bold'>{ formattedCurrentDate }</div>
                <div className='d-flex flex-grow-1 flex-shrink-1 w-100 justify-content-center'>
                    <span className='calendar-month'>{ formattedCurrentDateMonth }</span>
                </div>
            </div>
        }
    })*/

    const navigate = useNavigate();
    const current = dayjs(currentDate);

    const handleDateChange = (date) => {
        navigate(`/${url}/${date.format('YYYY-MM-DD')}`);
    };

    const dateBefore = () => {
        window.location = `/${url}/${current.add(-1, 'day').format('YYYY-MM-DD')}`;
    };

    const dateAfter= () => {
        window.location = `/${url}/${current.add(1, 'day').format('YYYY-MM-DD')}`;
    };

    return (
        <div className='calendar-bg d-flex w-100 justify-content-center align-items-center p-3'>
            <div className='d-flex flex-fill'>
                <span style={{color: '#fff', fontSize: '22px', fontWeight: 'bold'}}>Tableau de Bord</span>
            </div>
            <div className='d-flex flex-fill justify-content-center align-items-center'>
                <i role='presentation' onClick={() => dateBefore()} className='me-2 fa-solid fa-chevron-left fs-3'></i>
                <div className='calendar-datepicker'>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                        <DatePicker
                            onChange={handleDateChange}
                            defaultValue={current}
                            loading={false}
                        />
                    </LocalizationProvider>
                </div>
                <i role='presentation' onClick={() => dateAfter()} className='ms-2 fa-solid fa-chevron-right fs-3'></i>
            </div>
            <div className='d-flex flex-fill justify-content-end'>
                <ChangeCanteen location={location}/>
            </div>
        </div>
    )
}

export default MealCalendar

import React, { useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import LoginTemplate from '../../components/Login/LoginTemplate';
import Login from '../../components/Login/Login';
import { useCookies } from 'react-cookie';

const LoginPage = () => {
    // Global
    const navigate = useNavigate();
    // Provider
    const { auth } = useAuth();
    const [, removeCookie] = useCookies();

    // Redirect if connected
    useEffect(() => {
        if (auth?.username) {
            navigate('/');
        } else {
            localStorage.removeItem('canteens');
            localStorage.removeItem('canteen_id');
            removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, undefined, {path: '/'});
        }
    }, [])

    return (
        <div className="login d-flex justify-content-center">
            <LoginTemplate child={<Login />}/>
        </div>
    );
};

export default LoginPage;

import React from 'react';
import Navigation from '../../components/Canteen/Navigation';
import Communications from '../../components/Canteen/Communications';

const CommunicationsPage = () => {
    return (
        <div className="communications d-flex">
            <Navigation/>
            <Communications/>
        </div>
    );
};

export default CommunicationsPage;

import React from 'react';

const StatisticGlobalDetails = ({type, name, value, secondValue, pastMonth}) => {
    const icon = type + '-icon';

    return (
        <div className={'statistic-details statistic-details-money'}>
            <div className='statistic-detail-title'>{name}</div>
            <div className={'hot-icon' === icon ? 'statistic-detail-value statistic-detail-meal' : 'statistic-detail-value'}><img src={`/images/${icon}.png`} alt=''></img><span>{value}</span></div>
            <div className='statistic-detail-evolution'>
                {
                    type === 'time' ? (
                        <span>{secondValue}% de vote sur {pastMonth}</span>
                    ) : type === 'review' ? (
                        <span>
                            { secondValue > 0 ? '+' + secondValue : secondValue }% vs {pastMonth}
                            { secondValue > 0 ? (
                                <i className='fa-solid fa-arrow-trend-up ms-1'></i>
                            ) : (
                                <i className='fa-solid fa-arrow-trend-down ms-1'></i>
                            )}
                        </span>
                    ) : (
                        <span>{secondValue} en {pastMonth}</span>
                    )
                }
            </div>
        </div>
    );
};

export default StatisticGlobalDetails;

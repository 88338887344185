import React from 'react';
import ErrorTemplate from '../../components/Error/ErrorTemplate';

const UnauthorizedPage = () => {
    return (
        <div className="unauthorized">
            <ErrorTemplate h1="Vous n'avez pas les droits" />
        </div>
    );
};

export default UnauthorizedPage;

import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import Navigation from '../../components/Canteen/Navigation';
import ProfileMenu from '../../components/Canteen/ProfileMenu';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import InputField from '../../components/InputField';
import { useCookies } from 'react-cookie';

const ProfilePage = () => {
    const [displayUserInfos, setDisplayUserInfos] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const { reset, register, handleSubmit, formState: { errors } } = useForm();
    const { auth, setAuth, setFlash, setShowFlash, setIsLoading } = useAuth();
    const [user, setUser] = useState({});
    const [, setCookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_user`]);

    // Form
    const { refUsername, ...inputPropsUsername } = register('email', {
        required: 'Adresse email obligatoire',
        maxLength: {
            value: 50,
            message: 'Maximum 50 caractères',
        },
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Mauvais format',
        }
    });

    const getUser = async () => {
        setIsLoading(true);
        const result = await axiosPrivate.get('/guests', { withCredentials: true })
        let data = result.data;
        setUser(data);
        reset({
            email: data.email,
        })
        setDisplayUserInfos(true);
        setIsLoading(false);
    };

    // Call Submit Register Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            await axiosPrivate.put('/guests', {
                first_name: user.first_name,
                last_name: user.last_name,
                email: data.email,
                phone: user.phone,
                address: user.address,
                additional_address: user.additional_address,
                zip: user.zip,
                city: user.city,
            }, { withCredentials: true })

            setAuth({ ...auth, username: data.email});
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ...auth, username: data.email}, { path: '/' })
            setFlash({ message: 'Infos changé avec succès', type: 'success' });
        } catch (err) {
            let error;
            if (err?.response?.status === 409) {
                error = 'Email déjà utilisé';
            } else {
                error = 'Une Erreur est survenue';
            }
            setFlash({ message: error });
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    const displayMobileMenu = () => {
        document.querySelector('.mobile-menu').classList.add('d-flex')
    }

    const hideMobileMenu = () => {
        const classes = document.querySelector('.mobile-menu').classList

        if( classes.contains('d-flex') ){
            document.querySelector('.mobile-menu').classList.remove('d-flex')
        }
    }


    useEffect(() => {
        getUser();
    }, []);

    return ( displayUserInfos &&
        <div className="profile d-flex">
            <Navigation />
            <div className='profile-detail min-vh-100'>
                <div className='profile-title'>
                    <span role='presentation' className='menu-hamburger' onClick={() => displayMobileMenu()}><img src='/images/menu-icon.png' alt='Menu'></img></span>
                    <span className='profile-title-text'>Mon profil</span>
                    <span className='profile-avatar'><img style={{height: '24px'}} src='/images/avatar.png' alt='Avatar'></img></span>
                </div>

                <div role='presentation' className='profile-global' onClick={() => hideMobileMenu()}>
                    <ProfileMenu active='profile'></ProfileMenu>
                    <div className="profile-content">
                        <form className="profile-form d-flex flex-column" onSubmit={ handleSubmit(submit) } >
                            <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1'>
                                <InputField color="#000" label="Email *" className="user-input" error={!!errors.username} helper={errors?.username?.message} inputRef={refUsername} inputProps={inputPropsUsername}></InputField>
                            </div>

                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <input className='user-submit' type='submit' value='Valider'></input>
                            </div>
                        </form>
                    </div>
                    <div className='profile-logo'>
                        <img src='/images/logo.png' alt='logo'></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;

import React from 'react';

const StatisticMealDetails = ({icon, name, value, count}) => {
    return (
        <div className='statistic-details statistic-details-meal'>
            <div className='statistic-detail-title'>{name}</div>
            <div className='statistic-meal-detail-value'>{value != '-' && <img src={`/images/${icon}`} alt=''></img>} {value}</div>
            <div className='statistic-detail-evolution'>{count} %</div>
        </div>
    );
};

export default StatisticMealDetails;

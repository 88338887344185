import React, {useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Alert from '../Alert';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';

const ResetPassword = () => {
    // Global
    const navigate = useNavigate();
    const { email, token } = useParams();
    // Form
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    // Provider
    const { setFlash, setShowFlash, setIsLoading } = useAuth();

    // Call Submit Reset Password Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            await axios.put('/users/password', {
                email: email,
                token: token,
                password: data.password,
            }, { withCredentials: true });

            setFlash({ message: 'Votre mot de passe est bien réinitialisé', type: 'success' });
            navigate('/login');
        } catch (err) {
            let error;
            let redirect = '/login';
            if (err?.response?.status === 400) {
                error = 'Le Token est expiré';
            } else if (err?.response?.status === 404) {
                error = 'Le Token est incorrect';
            } else {
                error = 'Erreur Serveur';
                redirect = '/500';
            }
            setFlash({ message: error });
            navigate(redirect);
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    // Check User / Token
    const checkToken = async() => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            let url = `/users/password/token?email=${email}&token=${token}`;
            await axios.get(url, { withCredentials: true });

        } catch (err) {
            let error;
            let redirect = '/login';
            if (err?.response?.status === 400) {
                error = 'Le Token est expiré';
            } else if (err?.response?.status === 404) {
                error = 'Le Token est incorrect';
            } else {
                error = 'Erreur Serveur';
                redirect = '/500';
            }
            setFlash({ message: error });
            setShowFlash(true);
            navigate(redirect);
        }

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        checkToken();
    }, [])

    return (
        <div className='login-form'>
            <span className="fw-bold mt-3">Réinitialisation de votre mot de passe</span>
            <form className="d-flex flex-column" onSubmit={handleSubmit(submit)}>
                <Alert/>
                <input className="login-field" type="password" placeholder="Nouveau Mot de passe" {...register('password', {required: true})} ></input>
                {errors?.password?.type === 'required' && <div className="text-danger small">{'Mot de passe obligatoire'}</div>}
                <input className="login-field" type="password" placeholder="Mot de passe à nouveau" {...register('repeatPassword', {validate: (value) => value === watch('password')})} ></input>
                {errors?.repeatPassword?.type === 'validate' && <div className="text-danger small">{'Mot de passe non identique'}</div>}
                <button className="login-submit" type="submit">Réinitialiser mon mot de passe</button>
            </form>
            <div className="login-back text-center">
                <span><Link to="/login"><u><b>Retour</b></u></Link></span>
            </div>
        </div>
    );
};

export default ResetPassword;

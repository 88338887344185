import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import StatisticGlobalDetails from './StatisticGlobalDetails';
import { Bar, BarChart, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import ChangeCanteen from './ChangeCanteen';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const StatisticsGlobal = () => {
    // Date
    const months = [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;

    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    const currentWeek = Math.ceil(days / 7);

    const { setIsLoading } = useAuth();
    const axiosPrivate = useAxiosPrivate()
    const [free, setFree] = useState(null);
    const [standard, setStandard] = useState(null);
    const [display, setDisplay] = useState(false);
    const canteenId = localStorage.getItem('canteen_id');
    const [monthStat1, setMonthStat1] = useState(currentMonth);
    const [monthStat2, setMonthStat2] = useState(currentMonth);
    const [monthStat3, setMonthStat3] = useState(currentMonth);
    const [weekStat1, setWeekStat1] = useState(currentWeek);

    // Pie Chart
    const radian = Math.PI / 180;
    const renderCustomizedLabelPie = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * radian);
        const y = cy + radius * Math.sin(-midAngle * radian);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };
    const colorGender = ['#4F81BE', '#B6CBE4'];
    const colorMeal = ['#4F81BE', '#B6CBE4', '#DAE5F1', '#80C5A7'];

    // Get Statistics
    const fetch = async() => {
        // Loader
        setIsLoading(true);

        // Call API
        const response = await axiosPrivate.get(`/canteens/statistics/global?canteenId=${canteenId}&monthStat1=${monthStat1}&monthStat2=${monthStat2}&monthStat3=${monthStat3}&weekStat1=${weekStat1}`, { withCredentials: true });

        setFree(response.data.stats.free);
        setStandard(response.data.stats.standard || null);

        // Display
        setDisplay(true);

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        fetch();
    }, [monthStat1, monthStat2, monthStat3, weekStat1]);

    const displayMobileMenu = () => {
        document.querySelector('.mobile-menu').classList.add('d-flex')
    }

    const hideMobileMenu = () => {
        const classes = document.querySelector('.mobile-menu').classList

        if( classes.contains('d-flex') ){
            document.querySelector('.mobile-menu').classList.remove('d-flex')
        }
    }


    return (
        <>
            { display &&
                <div className="d-flex col-12 col-md-10 align-items-center flex-column vh-100 global-stat">
                    <div className='statistics-header-mobile'>
                        <div className='statistics-header-menu' role='presentation' onClick={() => displayMobileMenu()}><img src='/images/menu-icon-white.png' alt='Menu'></img></div>
                        <div className='statistics-header-name'>Cantine du Sud</div>
                        <div className='statistics-header-avatar'>
                            <Link to='my-profile'>
                                <img src='/images/avatar-icon.png' alt='Menu'></img>
                            </Link>
                        </div>
                    </div>

                    <div className='statistics-body' role='presentation' onClick={() => hideMobileMenu()}>
                        <div className='statistics-heading'>Statistiques</div>

                        <div className='statistics-sub-heading'>
                            <div className='statistics-sub-heading-text statistics-sub-heading-active'>Général</div>

                            <Link to='/statistics/meal'>
                                <div className='statistics-sub-heading-text'>Alimentation</div>
                            </Link>

                            <Link to='/statistics/review'>
                                <div className='statistics-sub-heading-text border-0'>Satisfaction</div>
                            </Link>
                            <div className="d-flex justify-content-center div-change-canteen">
                                <ChangeCanteen location="/statistics/global"/>
                            </div>
                        </div>

                        <div className='statistics-overview flex-column'>
                            <div className='d-flex justify-content-center'>
                                <div className='statistic-button'>
                                    { monthStat1 === currentMonth &&
                                        <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-left' onClick={() => setMonthStat1(currentMonth - 1)}></i>
                                    }
                                    <span className='flex-grow-1 text-center'>{months[monthStat1 - 1]}</span>
                                    { monthStat1 !== currentMonth &&
                                        <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-right' onClick={() => setMonthStat1(currentMonth)}></i>
                                    }
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <StatisticGlobalDetails type='time' name='Horaire préféré' value={free.current.favorite_timeslot} secondValue={free.past.favorite_timeslot_percent} pastMonth={free.past.month}></StatisticGlobalDetails>
                                <StatisticGlobalDetails type='review' name='Nombre de votes' value={free.current.review_count} secondValue={new Intl.NumberFormat('fr-FR', {minimumFractionDigits: 0, maximumFractionDigits: 0}).format(((free.current.review_count - free.past.review_count) / free.past.review_count) * 100)} pastMonth={free.past.month}></StatisticGlobalDetails>
                                <StatisticGlobalDetails type='hot' name='Le plus plebiscité' value={free.current.favorite_meal} secondValue={free.past.favorite_meal} pastMonth={free.past.month}></StatisticGlobalDetails>
                            </div>
                        </div>

                        <div className={standard === null ? 'statistics-content statistics-content-blur' : 'statistics-content'}>
                            <div className='d-flex'>
                                <div className='stats-charts-container' style={{minWidth:'48%'}}>
                                    <div className='stats-charts-head-title'>Répartition selon le sexe</div>
                                    <div className='d-flex justify-content-center'>
                                        <div className='statistic-button'>
                                            {monthStat2 === currentMonth &&
                                                <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-left' onClick={() => setMonthStat2(currentMonth - 1)}></i>
                                            }
                                            <span className='flex-grow-1 text-center'>{months[monthStat2 - 1]}</span>
                                            {monthStat2 !== currentMonth &&
                                                <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-right' onClick={() => setMonthStat2(currentMonth)}></i>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <ResponsiveContainer width="100%" minWidth={300} minHeight={300}>
                                            <PieChart>
                                                <Tooltip/>
                                                <Pie
                                                    nameKey="name"
                                                    dataKey="value"
                                                    data={standard !== null ? standard.gender : []}
                                                    label={renderCustomizedLabelPie}
                                                    labelLine={false}
                                                >
                                                    {standard !== null && standard.gender.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={colorGender[index % colorGender.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>

                                <div className='stats-charts-container' style={{minWidth:'48%'}}>
                                    <div className='stats-charts-head-title'>Plats choisis</div>
                                    <div className='d-flex justify-content-center'>
                                        <div className='statistic-button'>
                                            {monthStat3 === currentMonth &&
                                                <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-left' onClick={() => setMonthStat3(currentMonth - 1)}></i>
                                            }
                                            <span className='flex-grow-1 text-center'>{months[monthStat3 - 1]}</span>
                                            {monthStat3 !== currentMonth &&
                                                <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-right' onClick={() => setMonthStat3(currentMonth)}></i>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <ResponsiveContainer width="100%" minWidth={300} minHeight={300}>
                                            <PieChart>
                                                <Tooltip/>
                                                <Pie
                                                    nameKey="name"
                                                    dataKey="value"
                                                    data={standard !== null ? standard.meal : []}
                                                    label={renderCustomizedLabelPie}
                                                    labelLine={false}
                                                >
                                                    {standard !== null && standard.meal.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={colorMeal[index % colorMeal.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex affluence-preview'>
                                <div className='stats-charts-container' style={{minWidth:'100%'}}>
                                    <div className='stats-charts-head-title'>Prévision d’affluence</div>
                                    <div className='d-flex justify-content-center'>
                                        <div className='statistic-button'>
                                            {weekStat1 === currentWeek &&
                                                <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-left' onClick={() => setWeekStat1(currentWeek - 1)}></i>
                                            }
                                            <span className='flex-grow-1 text-center'>S{weekStat1}</span>
                                            {weekStat1 !== currentWeek &&
                                                <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-right' onClick={() => setWeekStat1(currentWeek)}></i>
                                            }
                                        </div>
                                    </div>
                                    <div style={{width: '350px', minHeight: '300px'}}>
                                        <ResponsiveContainer width="100%" minWidth={350} minHeight={300}>
                                            <BarChart margin={{ top: 20 }} data={standard !== null ? standard.affluence : []}>
                                                <Tooltip/>
                                                <XAxis dataKey="name"/>
                                                <Bar dataKey="value" fill="#C3E3D4" label={{ position: 'top' }} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { standard === null &&
                            <div className='statistics-offer-upgrade'>
                                <div className='statistics-offer-title'>Statistiques générales sur vos convives</div>
                                <div className='statistics-offer-subtitle'>Envie d’en savoir plus sur vos convives ?</div>
                                <div className='statistics-offer-content'>Découvrer la tendance des convives qui déjeunent chaque jour à la cantine et comprenez comment améliorer votre taux de captation </div>
                                <div className='statistics-offer-available'>Disponible en offre Standard et Premium</div>
                                <Link to='https://meetings-eu1.hubspot.com/axel-galliano/revue-offres-jabu'>
                                    <div className='statistics-offer-available-button'>Passer à l’offre supérieure</div>
                                </Link>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default StatisticsGlobal;

import React from 'react';
import Navigation from '../../components/Canteen/Navigation';
import ProfileMenu from '../../components/Canteen/ProfileMenu';

const HelpWorkPage = () => {
    return (
        <div className="profile d-flex">
            <Navigation />
            <div className='profile-detail min-vh-100'>
                <div className='profile-title'>Aide et support</div>
                <div className='d-flex col-md-2 p-5 w-100 flex-grow-1'>
                    <ProfileMenu active='help'></ProfileMenu>
                    <div className="d-flex flex-column align-items-center p-2 m-4">
                        Cras nec lacinia nunc. Duis vel sem vehicula, ultricies sapien sit amet, fermentum urna. Aenean eget orci quis lacus mattis mollis vitae ac libero. Sed urna ipsum, convallis ut facilisis ut, auctor id mauris. Aliquam non libero vitae mi mollis sagittis eget vel velit. Mauris non magna efficitur, vehicula neque sit amet, tempus leo. Suspendisse ut maximus nisi, ac ornare augue. Aenean tempus enim mauris, quis porta lorem lobortis non.
                        <br></br>
                        <br></br>
                        Phasellus sit amet vestibulum neque. Fusce ullamcorper varius justo sit amet sodales. Cras in facilisis magna. Mauris eros nunc, molestie eu arcu nec, pellentesque posuere elit. Proin eu tortor aliquam, congue urna vitae, faucibus purus. In posuere imperdiet diam, vel condimentum sem gravida venenatis. Proin at metus quam. Praesent lectus felis, faucibus in euismod non, mollis vitae magna. Nunc nulla dui, elementum quis neque pulvinar, consectetur auctor ante. Curabitur quis vehicula nulla. Nulla rutrum in mauris eget condimentum. Vivamus tincidunt malesuada hendrerit.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HelpWorkPage;

import React from 'react';
import LoginTemplate from '../../components/Login/LoginTemplate';
import ForgotPassword from '../../components/Login/ForgotPassword';

const ForgotPasswordPage = () => {
    return (
        <div className="login forgot-password">
            <LoginTemplate child={<ForgotPassword />}/>
        </div>
    );
};

export default ForgotPasswordPage;

import React from 'react';
import Navigation from '../../components/Canteen/Navigation';
import StatisticsGlobal from '../../components/Canteen/StatisticsGlobal';

const StatisticsGlobalPage = () => {
    return (
        <div className="statistics d-flex">
            <Navigation color='-white' />
            <StatisticsGlobal />
        </div>
    );
};

export default StatisticsGlobalPage;

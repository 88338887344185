import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const DashboardDetails = ({free, standard}) => {
    return (
        <TableContainer component={Paper} sx={{ boxShadow: {xs: 'none'}, backgroundColor: 'white', padding: {md: '20px 40px'} }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ color: '#635C5C', padding: {xs: '5px 10px;', md: '16px'}, fontSize: {xs: '13px', md: '15px'},  minWidth: {xl: 120} }}>Type</TableCell>
                        <TableCell sx={{ color: '#635C5C', padding: {xs: '5px 10px;', md: '16px'}, fontSize: {xs: '13px', md: '15px'},  minWidth: {xl: 160} }}>Nom</TableCell>
                        <TableCell sx={{ color: '#635C5C', padding: {xs: '5px 10px;', md: '16px'}, fontSize: {xs: '13px', md: '15px'},  minWidth: {xs: 80, xl: 160} }}>% de votes</TableCell>
                        <TableCell sx={{ textAlign:'center', color: '#635C5C', padding: {xs: '5px 10px;', md: '16px'}, fontSize: {xs: '13px', md: '15px'},  minWidth: {xl: 100} }}>Convives estimés</TableCell>
                        {/*<TableCell sx={{ textAlign:'center', color: '#635C5C', padding: {xs: '5px 10px;', md: '16px'}, fontSize: {xs: '13px', md: '15px'},  minWidth: {xl: 100} }}>OptiStock</TableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { free.menu && Object.keys(free.menu).map((menuId) => {
                        const percent = free.menu[menuId].percent

                        return <TableRow
                            key={menuId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell sx={{ color: {xs: '#635C5C', md: 'inherit'}, padding: {xs: '0px 5px', md: '16px'}, fontSize: {xs: '13px', md: '15px'} }}>{free.menu[menuId].type}</TableCell>
                            <TableCell sx={{ padding: {xs: '0px 5px', md: '16px'}, fontSize: {xs: '13px', md: '15px'} }}>{free.menu[menuId].name}</TableCell>
                            <TableCell sx={{ padding: {xs: '5px 2px', md: '16px'}, fontSize: {xs: '13px', md: '15px'} }}>
                                <div className="progress">
                                    <div className="progress-bar stat-progress-bar" style={{width: `${percent}%`}} role="progressbar" aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div className="stat-progress">{percent}%</div>
                            </TableCell>
                            <TableCell sx={{ textAlign:'center', padding: {xs: '0px 5px', md: '16px'}, fontSize: {xs: '13px', md: '13px'} }}>{Math.round(standard?.attendance * (percent / 100))}</TableCell>
                            {/*<TableCell sx={{ textAlign:'center', padding: {xs: '0px 5px', md: '16px'}, fontSize: {xs: '13px', md: '13px'} }}>{premium?.menu[menuId].stock}</TableCell>*/}
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DashboardDetails;

import React from 'react';
import ErrorTemplate from '../../components/Error/ErrorTemplate';

const NotFoundPage = () => {
    return (
        <div className="not-found">
            <ErrorTemplate h1="Cette page n'existe pas" />
        </div>
    );
};

export default NotFoundPage;

import React from 'react';
import Navigation from '../../components/Canteen/Navigation';
import AttendanceList from '../../components/Canteen/AttendanceList';

const AttendanceListPage = () => {
    return (
        <div className="dashboard attendance-list d-flex">
            <Navigation color='-white' />
            <AttendanceList />
        </div>
    );
};

export default AttendanceListPage;

import React from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../../components/Canteen/Navigation';
import UnsoldMeals from '../../components/Canteen/UnsoldMeals';

const UnsoldMealsPage = () => {
    let params = useParams()
    let today = new Date().toJSON().slice(0, 10)
    let currentDate = params?.date ? params.date : today
    let bef = new Date(currentDate)
    let aft = new Date(currentDate)
    let dayBefore = new Date(bef.setDate(bef.getDate() - 1)).toJSON().slice(0, 10)
    let dayAfter = new Date(aft.setDate(aft.getDate() + 1)).toJSON().slice(0, 10)

    console.log('current date is:' + currentDate)
    console.log('the day before was:' + dayBefore)
    console.log('the day after is:' + dayAfter)

    return (
        <div className="unsold-meals d-flex">
            <Navigation/>
            <UnsoldMeals/>
        </div>
    );
};

export default UnsoldMealsPage;

import React from 'react';
import { useForm } from 'react-hook-form';
import Navigation from '../../components/Canteen/Navigation';
import ProfileMenu from '../../components/Canteen/ProfileMenu';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import InputField from '../../components/InputField';

const UpdatePasswordPage = () => {
    const axiosPrivate = useAxiosPrivate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { setFlash, setShowFlash, setIsLoading } = useAuth();

    // Form
    const { refPassword, ...inputPropsPassword } = register('password', {
        required: 'Mot de passe obligatoire',
        minLength: {
            value: 8,
            message: 'Minimum 8 caractères',
        },
    });
    const { refRepeatPassword, ...inputPropsRepeatPassword } = register('repeatPassword', {
        validate: (value) => value === watch('password') || 'Mot de passe non identique',
    });

    // Call Submit Register Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            await axiosPrivate.put('/canteens/password', {
                password: data.password,
            }, { withCredentials: true })
            setFlash({ message: 'Le mot de passe a été changé', type: 'success' });
        } catch (error) {
            console.log(error)
            setFlash({ message: 'Une Erreur est survenue' });
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }


    return (
        <div className="profile d-flex">
            <Navigation />
            <div className='profile-detail min-vh-100'>
                <div className='profile-title'>
                    <span role='presentation' className='menu-hamburger'><img src='/images/menu-icon.png' alt='Menu'></img></span>
                    <span className='profile-title-text'>Modifier mon mot de passe</span>
                    <span className='profile-avatar'><img style={{height: '24px'}} src='/images/avatar.png' alt='Avatar'></img></span>
                </div>

                <div className='d-flex col-md-2 p-5 w-100 flex-grow-1'>
                    <ProfileMenu active='password'></ProfileMenu>
                    <div className="d-flex flex-column align-items-center p-2 m-4 flex-grow-1">
                        <form className="profile-form d-flex flex-column" onSubmit={ handleSubmit(submit) } >
                            <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1'>
                                <InputField type="password" color="#000" label="Nouveau Mot de passe *" className="user-input" error={!!errors.password} helper={errors?.password?.message} inputRef={refPassword} inputProps={inputPropsPassword}></InputField>
                                <InputField type="password" color="#000" label="Répéter le mot de passe *" className="user-input" error={!!errors.repeatPassword} helper={errors?.repeatPassword?.message} inputRef={refRepeatPassword} inputProps={inputPropsRepeatPassword}></InputField>
                            </div>

                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <input className='user-submit' type='submit' value='Définir un nouveau mot de passe'></input>
                                <input className='user-cancel' type='button' value='Annuler'></input>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdatePasswordPage;

import { useEffect } from 'react';
import useRefreshToken from './useRefreshToken';
import useAuth from './useAuth';
import { axiosPrivate } from '../api/axios';

const useAxiosPrivate = () => {
    // Global
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        // Add Authorization all Request
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.token}`;
                }

                return config;
            }, (error) => Promise.reject(error)
        );

        // Check response
        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                // Check Authorization url
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    // Refresh Session
                    const newAccessToken = await refresh();
                    if (newAccessToken !== null) {
                        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                        return axiosPrivate(prevRequest);
                    }
                }

                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh])

    return axiosPrivate;
};

export default useAxiosPrivate;

import React, { useEffect } from 'react';
import useAuth from '../hooks/useAuth';

const Alert = () => {
    const { flash, showFlash, setShowFlash } = useAuth();

    // Remove Flash
    useEffect(() => {
        if (showFlash) {
            setTimeout(() => {
                setShowFlash(false);
            }, 3000);
        }
    }, [showFlash])

    return (
        <>
            { showFlash && flash.message &&
                <div className={'alert alert-' + (flash?.type ? flash.type : 'danger')} role="alert">
                    { flash.message }
                </div>
            }
        </>
    );
};

export default Alert;

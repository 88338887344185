import React from 'react';

const StatisticReviewDetails = ({name, value}) => {
    let icon = '';
    if( null == value ){
        value = '-'
        icon = <img src='/images/neutral-review-icon.png' alt='neutral'></img>
    }else if( value < 50 ){
        value = value + '%'
        icon = <img src='/images/bad-review-icon.png' alt='bad'></img>
    } else if( value >= 50 && value < 75 ){
        value = value + '%'
        icon = <img src='/images/neutral-review-icon.png' alt='neutral'></img>
    } else if( value >= 75 ){
        value = value + '%'
        icon = <img src='/images/good-review-icon.png' alt='good'></img>
    }

    return (
        <div className='statistic-details statistic-details-review'>
            <div className='statistic-detail-title'>{name}</div>
            <div className='statistic-detail-value'>{icon} {value}</div>
        </div>
    );
};

export default StatisticReviewDetails;

import React from 'react';
import Navigation from '../../components/Canteen/Navigation';
import ProfileMenu from '../../components/Canteen/ProfileMenu';

const HelpPage = () => {
    return (
        <div className="profile d-flex">
            <Navigation />
            <div className='profile-detail min-vh-100'>
                <div className='profile-title'>
                    <span role='presentation' className='menu-hamburger'><img src='/images/menu-icon.png' alt='Menu'></img></span>
                    <span className='profile-title-text'>Aide et support</span>
                    <span className='profile-avatar'><img style={{height: '24px'}} src='/images/avatar.png' alt='Avatar'></img></span>
                </div>
                <div className='d-flex col-md-2 p-5 w-100 flex-grow-1'>
                    <ProfileMenu active='help'></ProfileMenu>
                    <div className="d-flex flex-column align-items-center p-2 m-4 w-100">
                        <div className="user-title d-flex justify-content-center" style={{fontSize: '30px', textAlign: 'center'}}>
                            AIDE ET SUPPORT
                        </div>

                        <div className="cgu d-flex flex-column mt-4">
                            <p className='answer'>Contactez notre équipe de support technique via <a className='link' href='mailto:support@jabu-app.com?subject=Demande de support JABU'>support@jabu-app.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HelpPage;

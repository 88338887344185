import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Navigation from '../../components/Canteen/Navigation';
import ProfileMenu from '../../components/Canteen/ProfileMenu';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';

const NotificationPage = () => {
    // Global
    const axiosPrivate = useAxiosPrivate();
    const [display, setDisplay] = useState(false);
    // Form
    const { reset, register, handleSubmit } = useForm();
    // Provider
    const { setFlash, setShowFlash, setIsLoading } = useAuth();

    // Call Submit Canteen Setting Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            await axiosPrivate.put('/canteens/user/notification', {
                menu_email: data.menuEmail,
                menu_push: data.menuPush,
                unsold_email: data.unsoldEmail,
                unsold_push: data.unsoldPush,
                community_email: data.communityEmail,
                community_push: data.communityPush,
                news_email: data.newsEmail,
                news_push: data.newsPush,
            }, { withCredentials: true });

            setFlash({ message: 'Modification effectuée', type: 'success' });
        } catch (err) {
            setFlash({ message: 'Erreur Serveur' });
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    // Get Canteen Notification
    const fetch = async() => {
        // Loader
        setIsLoading(true);

        // Call API
        const response = await axiosPrivate.get('/canteens/user/notification', { withCredentials: true });
        let data = response.data;
        reset({
            menuEmail: data.menu_email,
            menuPush: data.menu_push,
            unsoldEmail: data.unsold_email,
            unsoldPush: data.unsold_push,
            communityEmail: data.community_email,
            communityPush: data.community_push,
            newsEmail: data.news_email,
            newsPush: data.news_push,
        });

        // Display Form
        setDisplay(true);

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        fetch();
    }, []);

    return (
        <div className="profile settings d-flex">
            <Navigation />
            <div className='profile-detail min-vh-100'>
                <div className='profile-title'>
                    <span role='presentation' className='menu-hamburger'><img src='/images/menu-icon.png' alt='Menu'></img></span>
                    <span className='profile-title-text'>Mes préférences de Notification</span>
                    <span className='profile-avatar'><img style={{height: '24px'}} src='/images/avatar.png' alt='Avatar'></img></span>
                </div>

                <div className='d-flex col-md-2 p-5 w-100 flex-grow-1'>
                    <ProfileMenu active='notification'></ProfileMenu>
                    <div className="d-flex flex-column align-items-center p-2 m-4 flex-grow-1">
                        <span className="sub-title">Choisissez les notifications Push ou par e-mail que vous souhaitez recevoir. Ces préférences s&apos;appliquent uniquement aux notifications Push et par e-mail</span>
                        { display &&
                            <form className="d-flex align-items-center flex-column w-100" onSubmit={ handleSubmit(submit) } >
                                <div className="w-100 my-3">
                                    <span className="notification-title"></span>
                                    <span className="notification-select">Email</span>
                                    <span className="notification-select">Push</span>
                                </div>
                                <div className="w-100 my-3">
                                    <span className="notification-title">Mise à jour de vos menu</span>
                                    <span className="notification-select">
                                        <input type="checkbox" name="menu-email" {...register('menuEmail')} />
                                    </span>
                                    <span className="notification-select">
                                        <input type="checkbox" name="menu-push" {...register('menuPush')} />
                                    </span>
                                </div>
                                <div className="w-100 my-3">
                                    <span className="notification-title">Renseignement des invendus</span>
                                    <span className="notification-select">
                                        <input type="checkbox" name="unsold-email" {...register('unsoldEmail')} />
                                    </span>
                                    <span className="notification-select">
                                        <input type="checkbox" name="unsold-push" {...register('unsoldPush')} />
                                    </span>
                                </div>
                                <div className="w-100 my-3">
                                    <span className="notification-title">Message communauté Jabu</span>
                                    <span className="notification-select">
                                        <input type="checkbox" name="community-email" {...register('communityEmail')} />
                                    </span>
                                    <span className="notification-select">
                                        <input type="checkbox" name="community-push" {...register('communityPush')} />
                                    </span>
                                </div>
                                <div className="w-100 my-3">
                                    <span className="notification-title">Dernières actualités et bons plans</span>
                                    <span className="notification-select">
                                        <input type="checkbox" name="news-email" {...register('newsEmail')} />
                                    </span>
                                    <span className="notification-select">
                                        <input type="checkbox" name="news-push" {...register('newsPush')} />
                                    </span>
                                </div>
                                <div className='d-flex flex-column align-items-center justify-content-center my-3'>
                                    <input className='user-submit' type='submit' value='Valider'></input>
                                    <input className='user-cancel' type='button' value='Annuler'></input>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationPage;

import React from 'react';
import Navigation from '../../components/Canteen/Navigation';
import AdminDashboard from '../../components/Canteen/AdminDashboard';

const AdminDashboardPage = () => {
    return (
        <div className="admin-dashboard d-flex">
            <Navigation color='-white' />
            <AdminDashboard />
        </div>
    );
};

export default AdminDashboardPage;

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const ProfileMenuItem = ({title, icon, link, active=false}) => {
    const { setModal, setShowModal } = useAuth();
    const navigate = useNavigate();

    const logout = async () => {
        setShowModal(false)
        navigate('/logout')
    }

    const confirm = async () => {
        setModal({content: 
            <div className='d-flex flex-column align-items-center'>
                <div className='logout-confirm-title'>Confirmer la déconnexion</div>
                <div role='presentation' className='logout-confirm' onClick={() => logout()}>Valider</div>
                <div role='presentation' className='logout-cancel' onClick={() => setShowModal(false)}>Annuler</div>
            </div>
        })
        setShowModal(true)
    }

    let activeClassName = ''
    if( active ){
        activeClassName = 'profile-menu-item-active'
    }

    if( link != undefined ){
        return (
            <Link to={link}>
                <div className={`profile-menu-item ${activeClassName}`}>
                    <span className='profile-menu-item-img'><img className='menu-item-icon' alt={title} src={`images/${icon}-icon.png`}></img></span>
                    <span className='profile-menu-item-title'>{title}</span>
                    <span className='profile-menu-item-access'>
                        <i className="fa-solid fa-chevron-right"></i>
                    </span>
                </div>
            </Link>
        );
    }else{
        return (
            <div role='presentation' className={`profile-menu-item ${activeClassName}`} onClick={() => confirm()}>
                <span className='profile-menu-item-img'><img className='menu-item-icon' alt={title} src={`images/${icon}-icon.png`}></img></span>
                <span className='profile-menu-item-title'>{title}</span>
                <span className='profile-menu-item-access'>
                    <i className="fa-solid fa-chevron-right"></i>
                </span>
            </div>
        );
    }
};

export default ProfileMenuItem;

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';
import Alert from '../Alert';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';

const Login = () => {
    // Global
    const navigate = useNavigate();
    const [, setCookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_user`]);
    // Form
    const { register, handleSubmit, formState: { errors } } = useForm();
    // Provider
    const { setAuth, setFlash, setShowFlash, setIsLoading } = useAuth();

    // Call Submit Login Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            const response = await axios.post('/users/login', {
                username: data.username,
                password: data.password,
            }, { withCredentials: true });

            // Data
            let token = response.data.token;
            let refreshToken = response.data.refresh_token;
            let jwtDecode = jwt_decode(token);
            let username = jwtDecode.username;
            let roles = jwtDecode.roles;
            let lastName = jwtDecode.last_name;
            let firstName = jwtDecode.first_name;

            if (roles.includes('ROLE_CANTEEN')) {
                setAuth({ username, lastName, firstName, roles, token, refreshToken });
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { username, lastName, firstName, roles, token, refreshToken }, { path: '/' });

                // Add Canteen to LocalStorage
                const canteens = await axios.get('/canteens', {
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token},
                    withCredentials: true ,
                });
                let storageCanteen = [];
                canteens.data.canteens.map((canteen) => {
                    storageCanteen.push({
                        'id': canteen.id,
                        'name': canteen.name,
                    })
                })
                localStorage.setItem('canteens', JSON.stringify(storageCanteen));
                localStorage.setItem('canteen_id', canteens.data.canteenId);

                navigate('/');
            } else {
                navigate('/unauthorized');
            }
        } catch (err) {
            setFlash({ message: 'Email ou Mot de passe incorrect' });
            setShowFlash(true);
        }

        // Loader
        setIsLoading(false);
    }

    return (
        <div className='login-form'>
            <span className="login-form-headtitle">Se connecter à votre espace cuisine</span>
            <form className="d-flex flex-column" onSubmit={ handleSubmit(submit) } >
                <Alert />
                <input className="login-field" type="text" placeholder="Adresse email" { ...register('username', { required: true }) } />
                { errors?.username?.type === 'required' && <div className="text-danger small">{ 'Champ Adresse email obligatoire' }</div> }
                <input className="login-field" type="password" placeholder="Mot de passe" { ...register('password', { required: true }) } />
                { errors?.password?.type === 'required' && <div className="text-danger small">{ 'Champ Mot de passe obligatoire' }</div> }
                <button className="login-submit" type="submit">Se connecter</button>
            </form>

            <span className="login-forgot-pass">
                <Link to="/forgot-password">Mot de passe oublié ?</Link>
            </span>

            <span className="login-app">
                <Link to="https://jabu-app.com">Se connecter en tant que convive</Link>
            </span>
        </div>
    );
};

export default Login;

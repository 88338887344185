import React from 'react';
import ProfileMenuItem from './ProfileMenuItem';

const ProfileMenu = ({active}) => {
    let profileActive, passwordActive, confidentialActive, CGUActive, helpActive, settingActive
    profileActive = ('profile' == active) ? true : false
    passwordActive = ('password' == active) ? true : false
    confidentialActive = ('confidential' == active) ? true : false
    CGUActive = ('cgu' == active) ? true : false
    helpActive = ('help' == active) ? true : false
    settingActive = ('setting' == active) ? true : false
    //notificationActive = ('notification' == active) ? true : false

    return (
        <div className='d-flex flex-column' style={{minWidth:'40%', height: '480px'}}>
            <ProfileMenuItem icon='profile' title='Mon Profil' link='/my-profile' active={profileActive}></ProfileMenuItem>
            {/*<ProfileMenuItem icon='house' title='Mes cantines' link='/my-canteens'></ProfileMenuItem>*/}
            <ProfileMenuItem icon='settings' title='Mes informations cantines' link='/my-canteen' active={settingActive}></ProfileMenuItem>
            <ProfileMenuItem icon='shield' title='Mon mot de passe' link='/my-password' active={passwordActive}></ProfileMenuItem>
            <ProfileMenuItem icon='info' title='Aide et support' link='/help' active={helpActive}></ProfileMenuItem>
            {/*<ProfileMenuItem icon='reminder' title='Mes préférences de notification' link='/notifications' active={notificationActive}></ProfileMenuItem>*/}
            <ProfileMenuItem icon='list' title='Conditions générales d&apos;utilisation' link='/cgu' active={CGUActive}></ProfileMenuItem>
            <ProfileMenuItem icon='lock' title='Politique de confidentialité' link='/confidential' active={confidentialActive}></ProfileMenuItem>
            <ProfileMenuItem icon='logout' title='Déconnexion'></ProfileMenuItem>
        </div>
    );
};

export default ProfileMenu;

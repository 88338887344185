import React, { useEffect, useState } from 'react';
import StatisticReviewDetails from './StatisticReviewDetails';
import { Link } from 'react-router-dom';
// import { PieChart, ResponsiveContainer } from 'recharts';
import ChangeCanteen from './ChangeCanteen';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const StatisticsReview = () => {
    // Date
    const months = [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;

    const { setIsLoading } = useAuth();
    const axiosPrivate = useAxiosPrivate()
    const [free, setFree] = useState(null);
    const [premium, setPremium] = useState(null);
    const [display, setDisplay] = useState(false);
    const canteenId = localStorage.getItem('canteen_id');
    const [monthStat1, setMonthStat1] = useState(currentMonth);

    // Get Statistics
    const fetch = async() => {
        // Loader
        setIsLoading(true);

        // Call API
        const response = await axiosPrivate.get(`/canteens/statistics/review?canteenId=${canteenId}&monthStat1=${monthStat1}`, { withCredentials: true });

        setFree(response.data.stats.free);
        setPremium(response.data.stats.premium || null);

        // Display
        setDisplay(true);

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        fetch();
    }, [monthStat1]);

    const displayMobileMenu = () => {
        document.querySelector('.mobile-menu').classList.add('d-flex')
    }

    const hideMobileMenu = () => {
        const classes = document.querySelector('.mobile-menu').classList

        if( classes.contains('d-flex') ){
            document.querySelector('.mobile-menu').classList.remove('d-flex')
        }
    }

    return (
        <>
            { display &&
                <div className="d-flex col-12 col-md-10 align-items-center flex-column vh-100 global-stat">
                    <div className='statistics-header-mobile'>
                        <div className='statistics-header-menu' role='presentation' onClick={() => displayMobileMenu()}><img src='/images/menu-icon-white.png' alt='Menu'></img></div>
                        <div className='statistics-header-name'>Cantine du Sud</div>
                        <div className='statistics-header-avatar'>
                            <Link to='my-profile'>
                                <img src='/images/avatar-icon.png' alt='Menu'></img>
                            </Link>
                        </div>
                    </div>

                    <div className='statistics-body' role='presentation' onClick={() => hideMobileMenu()}>
                        <div className='statistics-heading'>Statistiques</div>

                        <div className='statistics-sub-heading'>
                            {/*<Link to='/statistics/global'>
                                <div className='statistics-sub-heading-text'>Général</div>
                            </Link>*/}

                            <Link to='/statistics/meal'>
                                <div className='statistics-sub-heading-text'>Alimentation</div>
                            </Link>

                            <div className='statistics-sub-heading-text statistics-sub-heading-active border-0'>Satisfaction</div>
                            <div className="d-flex justify-content-center div-change-canteen">
                                <ChangeCanteen location="/statistics/review"/>
                            </div>
                        </div>

                        <div className='statistics-overview flex-column'>
                            <div className='d-flex justify-content-center'>
                                <div className='statistic-button review'>
                                    {monthStat1 === currentMonth &&
                                        <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-left' onClick={() => setMonthStat1(currentMonth - 1)}></i>
                                    }
                                    <span className='flex-grow-1 text-center'>{months[monthStat1 - 1]}</span>
                                    {monthStat1 !== currentMonth &&
                                        <i role='presentation' className='d-flex justify-content-center align-items-center flex-grow-1 fa-solid fa-chevron-right' onClick={() => setMonthStat1(currentMonth)}></i>
                                    }
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <StatisticReviewDetails name='Satisfaction générale' value={free.overall}></StatisticReviewDetails>
                                <StatisticReviewDetails name='Satisfaction entrées' value={free.entry}></StatisticReviewDetails>
                                <StatisticReviewDetails name='Satisfaction plats' value={free.meal}></StatisticReviewDetails>
                                <StatisticReviewDetails name='Satisfaction desserts' value={free.dessert}></StatisticReviewDetails>
                            </div>
                        </div>

                        <div className={premium === null ? 'statistics-content statistics-content-blur' : 'statistics-content'}>
                            {/* <div className='d-flex'>
                                <div className='stats-charts-container' style={{minWidth:'100%'}}>
                                    <div className='stats-charts-head-title'>Satisfaction des convives</div>
                                    <div>
                                        <ResponsiveContainer width="100%" minWidth={300} minHeight={300}>
                                            <PieChart width={400} height={400}>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div> */}

                            <div className='d-flex'>
                                {/* <div className='d-flex' style={{marginTop: '40px'}}> */}
                                <div className='stats-charts-container charts-desktop' style={{minWidth:'100%'}}>
                                    <div className='stats-charts-head-title'>Caractéristique des sélections</div>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th scope="col">Type</th>
                                                <th scope="col">Nom</th>
                                                <th scope="col" style={{textAlign: 'center'}}>Nombre de votes</th>
                                                <th scope="col" style={{textAlign: 'center'}}>Note globale</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                premium.today && premium.today.map((result) => (
                                                    <tr key={result.id}>
                                                        <td>{result.type}</td>
                                                        <td>{result.name}</td>
                                                        <td align='center'>{result.count}</td>
                                                        <td className={`chart-rate ${result.rate == '-' ? '' : result.rate >= 3.5 ? 'chart-rate-success' : result.rate >= 2 ? 'chart-rate-warning' : 'chart-rate-danger' }`} align='center'>{result.rate >= 0 ? new Intl.NumberFormat('fr-FR', {minimumFractionDigits: 1, maximumFractionDigits: 1}).format(result.rate) : result.rate}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className='stats-charts-container charts-mobile' style={{minWidth:'100%'}}>
                                    <div className='stats-charts-head-title'>Satisfactions par plats</div>
                                    <table className='table'>
                                        <tbody>
                                            {
                                                premium.today && premium.today5.overall.map((result) => (
                                                    <tr key={result.id}>
                                                        <td>{result.type}</td>
                                                        <td>{result.name}</td>
                                                        <td className={`chart-rate ${result.rate.replace('%', '') == '-' ? '' : result.rate.replace('%', '') >= 70 ? 'chart-rate-success' : result.rate.replace('%', '') >= 40 ? 'chart-rate-warning' : 'chart-rate-danger' }`} align='right' style={{width:'5%'}}>{result.rate}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* <div className='d-flex' style={{marginTop: '40px'}}>
                                <div className='stats-charts-container' style={{minWidth:'100%'}}>
                                    <div className='stats-charts-head-title'>Evolution de la satisfaction</div>
                                    <div>
                                        <ResponsiveContainer width="100%" minWidth={300} minHeight={300}>
                                            <PieChart width={400} height={400}>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        { premium === null &&
                            <div className='statistics-offer-upgrade'>
                                <div className='statistics-offer-title'>Statistiques sur la satisfaction de vos convives</div>
                                <div className='statistics-offer-subtitle'>Envie de savoir ce que pensent vos convives ?</div>
                                <div className='statistics-offer-content'>Analyser la satisfaction de vos convives afin de proposer une expérience optimale à la cantine</div>
                                <div className='statistics-offer-available'>Disponible en offre Premium</div>
                                <Link to='https://meetings-eu1.hubspot.com/axel-galliano/revue-offres-jabu'>
                                    <div className='statistics-offer-available-button statistics-offer-available-button-yellow'>Passer à l’offre supérieure</div>
                                </Link>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default StatisticsReview;

import React from 'react';
import Navigation from '../../components/Canteen/Navigation';
import Menus from '../../components/Canteen/Menus';

const MenusPage = () => {
    return (
        <div className="menus d-flex">
            <Navigation />
            <Menus />
        </div>
    );
};

export default MenusPage;

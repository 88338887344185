import { useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const NotificationPush = () => {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    // Process Notification
    const processNotification = async (permission) => {
        const pushSubscription = JSON.parse(localStorage.getItem('pushSubscription'));
        if (permission === 'granted' && !pushSubscription) {
            await registerNotification();
        } else if (permission === 'denied' && pushSubscription) {
            await unregisterNotification();
        }
    };

    // Subscription Navigator
    const getSubscriptionNavigator = async () => {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array('BGt1yIrnghy8PY11_yICJDrDyvz0RvXTVnrlc1780BmTywF79Mgz1tXUU8TslwrrdrfUUSTTsw-GpUsuaHAbrhg'),
        });

        const endpoint = subscription.endpoint;
        const publicKey = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh'))));
        const authToken = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth'))));

        return { endpoint, publicKey, authToken };
    }

    // Register Subscription
    const registerNotification = async () => {
        try {
            if ('PushManager' in window) {
                const { endpoint, publicKey, authToken } = await getSubscriptionNavigator();

                // Subscription DB
                const pushSubscription = await getPushNotification();
                let subscriptionExist = false;
                pushSubscription.map((subscription) => {
                    if (subscription.authToken === authToken && subscription.publicKey === publicKey) {
                        subscriptionExist = true;
                    }
                });

                // Post Subscription
                if (!subscriptionExist) {
                    await postPushNotification({endpoint, publicKey, authToken});
                }

                localStorage.setItem('pushSubscription', JSON.stringify({ endpoint, publicKey, authToken }));
            }
        } catch (error) {
            console.error('Error registering service worker:', error);
        }
    };

    // Unregister Subscription
    const unregisterNotification = async () => {
        localStorage.removeItem('pushSubscription');
    };

    // Encode
    const urlBase64ToUint8Array = (base64String) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    // Get Push Notification API
    const getPushNotification = async () => {
        let subscription;
        try {
            const response = await axiosPrivate.get('/guests/notification', { withCredentials: true });
            subscription = response.data.notifications;
        } catch (err) {
            console.log(err);
        }

        return subscription;
    }

    // Post Push Notification API
    const postPushNotification= async (data) => {
        try {
            await axiosPrivate.post('/guests/notification', {
                endpoint: data.endpoint,
                public_key: data.publicKey,
                auth_token: data.authToken,
            }, { withCredentials: true });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (auth?.username) {
            // Request Permission
            if ('Notification' in window) {
                Notification.requestPermission().then(permission => {
                    processNotification(permission);
                });
            }
        }
    }, [auth]);
};

export default NotificationPush;

const DishType = ({value}) => {
    let dishType = '';
    if( value === 1 ){
        dishType = 'Entrée'
    } else if( value === 2 ){
        dishType = 'Plat'
    } else if( value === 3 ){
        dishType = 'Dessert'
    } else if( value === 4 ){
        dishType = 'Accompagnement'
    } else if( value === 5 ){
        dishType = 'Fromage'
    }

    return dishType;
};

export default DishType;
